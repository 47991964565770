import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SimpleRestManagerService } from '../rest/simpleRestManager.service';
import { MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN } from '../../authentication';

@Injectable()
export class AuthGuardService implements CanActivateChild {

	constructor(private router: Router, private srms: SimpleRestManagerService) {
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return Observable.create((observer) => { this.pageLoadGuard(observer); });
	}

	/**
	 * Page guard procedure
	 * @param observer
	 */
	private pageLoadGuard(observer): void {

		let resolve = (status: boolean) => {
			if (!status) {
				this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN]);
			}
			observer.next(status);
			observer.complete();
		};

		resolve(this.srms.isLoggedIn());
	}
}
