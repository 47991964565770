import {
  AbstractModel,
  Id,
  Column,
  OneToOne
} from "../../common";
import { ClientModel } from './client.model';

/**
 * Model for a User REST object
 */
export class UserModel extends AbstractModel {

  @Id
  @Column()
  public id: number;

  @Column()
  public email: string;

  @Column()
  public password: string;

  @Column()
  public isAdmin: boolean;

  @OneToOne(ClientModel)
  public client?: ClientModel;

}
