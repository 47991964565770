import {
  AbstractModel,
  Id,
  Column,
  OneToOne,
  DateValue
} from "../../common";
import { ProjectModel } from './project.model';

/**
 * Model for a Flight REST object
 */
export class FlightModel extends AbstractModel {

  @Id
  @Column()
  public id: number;

  @Column()
  public name: string;

  @DateValue
  @Column()
  public date: Date;

  @OneToOne(ProjectModel)
  public project?: ProjectModel;

}
