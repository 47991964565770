import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';

import { Store, ModelService } from 'src/app/common';
import { UserModel } from '../../models/user.model';

@Component({
  selector: "createUserModal",
  templateUrl: "./createUser.html"
})
export class CreateUserModal {

  public edit: boolean = false;

  public isLoading: boolean = false;

  public form: FormGroup;

  public store: Store<UserModel>;

  public model: UserModel;

  public clientId: number;

  constructor(private modal: NzModalRef,
              private modelService: ModelService,
              private message: NzMessageService,
              private ts: TranslateService,
              private fb: FormBuilder,) {

    this.store = this.modelService.getStore('UserModel');

    setTimeout(() => {
      if(this.model !== undefined){
        this.edit = true;
        this.createFormEdit();
        this.fillForm();
      }else{
        this.createFormNew();
        this.model = new UserModel({
          client: {id: this.clientId}
        });
      }
    }, 0);
  }

  private createFormNew(): void {
		this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      isAdmin: [false, Validators.required]
		});
  }

  private createFormEdit(): void {
		this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null],
      isAdmin: [false, Validators.required]
		});
  }

  private fillForm(): void {
		this.model.populateFormWihtModel(this.form);
  }

  private destroyModal(success: boolean): void {
    this.modal.destroy({data: success});
  }

  public onCancelClick(): void {
    this.destroyModal(false);
  }

  public onSubmitClick(): void {
    this.form.controls.password.setValue(this.form.value.password.trim());

    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (!this.form.valid) return;

    this.edit ? this.update() : this.create();
  }

  private create(): void {
    this.isLoading = true;

    this.model.populateModelWithForm(this.form);
    this.store.create(this.model).subscribe((res) => {
      this.isLoading = false;
      if(res){
        this.message.success(this.ts.instant('drone.createUserModal.create.succesMsg'));
        this.destroyModal(true);
      }else{
        this.message.error(this.ts.instant('drone.createUserModal.create.errorMsg'));
      }
    });
  }

  private update(): void {
    this.isLoading = true;

    this.model.populateModelWithForm(this.form);
    this.store.update(this.model).subscribe((res) => {
      this.isLoading = false;
      if(res){
        this.message.success(this.ts.instant('drone.createUserModal.update.succesMsg'));
        this.destroyModal(true);
      }else{
        this.message.error(this.ts.instant('drone.createUserModal.update.errorMsg'));
      }
    });
  }
}
