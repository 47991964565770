import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd';

import * as moment from 'moment';
import * as turf from '@turf/turf';

import { Store, ModelService, SimpleRestManagerService } from 'src/app/common';
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, MODULE_PATH_DRONE_CLIENTS, MODULE_PATH_DRONE_FLIGHTS, MODULE_PATH_DRONE_VIDEOS } from '../drone.routes';
import { VideoModel } from '../models/video.model';
import { SRTModel } from '../models/srt.model';
import { ClientModel } from '../models/client.model';
import { ProjectModel } from '../models/project.model';
import { FlightModel } from '../models/flight.model';
import { CommentModel } from '../models/comment.model';
import { environment } from 'src/environments/environment';
import { PlayPageRouteExtraStateInterface } from "src/app/common/interfaces/playPageRouteExtraState.interface";

declare var videojs: any;

@Component({
  selector: "playPage",
  templateUrl: "./playPage.html",
  styleUrls: ["./playPage.scss"]
})
export class PlayPageComponent implements AfterViewInit, OnDestroy {

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;

  // === CLIENT ===

  public clientId: number;

  public client: ClientModel;

  public storeClient: Store<ClientModel>;

  // === PROJECT ===

  public projectId: number;

  public project: ProjectModel;

  public storeProject: Store<ProjectModel>;

  // === FLIGHT ===

  public flightId: number;

  public flight: FlightModel;

  public storeFlight: Store<FlightModel>;

  // === VIDEO ===

  public videoId: number;

  public store: Store<VideoModel>;

  public video: VideoModel;

  public videoPlayer: any;

  private videoPlayerOpt = {
    controlBar: {
      'pictureInPictureToggle': true,
      'volumePanel': false
    },
    responsive: true
  };

  public videoFullScreen: boolean = false;

  private currentVideoTime: number = 1;

  // === TABLE ===

  public tableDataAll: Array<any> = [];

  public tableData: Array<any> = [];

  private currentData: Array<any> = [];

  public expandSet = new Set<number>();

  public sorterKey: string = null;

  public sorterValue: string = null;

  public tablePageIndex: number = 0;

  public tablePageSize: number = 100;

  public maxCurrentPPM: number = 0;

  // === MAP ===

  private map: google.maps.Map;

  private marker: google.maps.Marker;

  private filterMarkers: Array<google.maps.Marker> = [];

  public filterMin: number;

  public filterMax: number;

  private mapObjects: Array<any> = [];

  private pointsCollection: turf.helpers.FeatureCollection<turf.helpers.Point>;

  // === COMMENT ===

  public commentStore: Store<CommentModel>;

  public inputValue: string[] = [];

  public submitting: boolean = false;

  public downloadPath: string = environment.serviceUrl + '/video/';

  public token = localStorage.getItem("AUTH_TOKEN");

  public isReady = {
    map: false,
    mapInitialZoom: false,
    video: false
  };

  // Composition

  public composition: Array<VideoCompositionInterface>;

  constructor(private modelService: ModelService,
              public ts: TranslateService,
              public srms: SimpleRestManagerService,
              private router: Router,
              private route: ActivatedRoute,
              private message: NzMessageService) {

    this.checkForRouterPayload();
    if (!this.composition) {
      this.openOneVideo();
    }
  }

  public ngAfterViewInit() {
    this.initMap();
    this.resizeEvents();
  }

  public ngOnDestroy() {
    this.videoPlayer && this.videoPlayer.pause();
    this.map && this.map.unbindAll();

    let player = document.getElementById("dron-video");
    player && videojs(player).dispose();
  }

  private openOneVideo(): void {
    this.route.params.subscribe(params => {
      this.clientId = typeof (params['clientId']) === 'undefined' ? null : +params['clientId'];
      this.projectId = typeof (params['projectId']) === 'undefined' ? null : +params['projectId'];
      this.flightId = typeof (params['flightId']) === 'undefined' ? null : +params['flightId'];
      this.videoId = typeof (params['videoId']) === 'undefined' ? null : +params['videoId'];

      if (!this.videoId) {
        this.router.navigate(['']);
        return;
      }

      this.init(true, true);
    });
  }

  private checkForRouterPayload(): void {
    let extras = this.router.getCurrentNavigation().extras;

    if (extras && extras.state && extras.state.videos) {
      this.composition = extras.state.videos.map(x => ({ ...x, isLoading: true, selected: false }));
      this.composition[0].selected = true;

      this.store = this.modelService.getStore('VideoModel');
      this.composition.forEach(this.loadDataById.bind(this))
    }
  }

  private loadDataById(item: VideoCompositionInterface): void {
    let rawVideo = this.store.getDataInstantById(this.videoId);
    let noSrt = !rawVideo || !rawVideo.srts || rawVideo.srts.length === 0; // SRT is not laoded when table request is called, so we need to check it here
    this.store.getDataById(item.videoId, noSrt).subscribe(res => {
      if (!res) {
        console.log('=== REPLAY: ', item.videoId);
        setTimeout(() => {
          this.loadDataById(item);
        }, 1000); // TODO fix on backend - this will replay requests if error 500 is recieved
        return;
      }

      res.prepareData();

      item.data = res;
      item.isLoading = false;

      let foorceMarkerAtStart: boolean = false;

      if (item.selected) {
        this.clientId = item.clientId;
        this.projectId = item.projectId;
        this.flightId = item.flightId;
        this.videoId = item.videoId;
        this.init(true);
        foorceMarkerAtStart = true;
      }

      this.drawOneFlightPath(item.data, foorceMarkerAtStart);
    });
  }

  private init(selectedVideo: boolean, drawFlightPath?: boolean, videoTimeStart?: number): void {
    // Client is loaded only if admin is logged in
    if (this.clientId) {
      this.storeClient = this.modelService.getStore('ClientModel');
      this.storeClient.getDataById(this.clientId).subscribe(res => {
        this.client = res;
      });
    }

    this.storeProject = this.modelService.getStore('ProjectModel');
    this.storeProject.getDataById(this.projectId).subscribe(res => {
      this.project = res;
    });

    this.storeFlight = this.modelService.getStore('FlightModel');
    this.storeFlight.getDataById(this.flightId).subscribe(res => {
      this.flight = res;
    });

    this.store = this.modelService.getStore('VideoModel');
    let rawVideo = this.store.getDataInstantById(this.videoId);
    let noSrt = !rawVideo || !rawVideo.srts || rawVideo.srts.length === 0; // SRT is not laoded when table request is called, so we need to check it here
    this.store.getDataById(this.videoId, noSrt).subscribe(res => {
      res.prepareData();
      this.video = res;

      if (selectedVideo) {
        this.tableDataAll = res.tableDataAll;
        this.tableData = res.tableDataAll;
        setTimeout(x => this.initVideo(this.video.path, videoTimeStart), 500); // TODO Maybe find a better solution rather that using timeout
      }

      if (drawFlightPath) {
        this.drawOneFlightPath(this.video);
      }
    });

    this.commentStore = this.modelService.getStore('CommentModel');
  }

  private initMap(): void {

    // Wait until lib is loaded
    if (typeof google == 'undefined') {
      setTimeout(() => this.initMap(), 500);
      return;
    }

    // Croatia centroid
    let coordinates = new google.maps.LatLng(44.08, 16.215);

    let mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 6,
    };

    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);

    this.map.addListener('click', this.onMapClick.bind(this));

    this.isReady.map = true;
  }

  private initVideo(videoUrl: string, videoTimeStart?: number): void {
    if (!this.videoPlayer) {
      this.videoPlayer = videojs("dron-video", this.videoPlayerOpt);
      this.videoPlayer.on("timeupdate", this.onVideoTimeUpdate.bind(this));
      this.videoPlayer.on("ended", this.onVideoEnd.bind(this));
    }

    // Ser video URL
    this.videoPlayer.src(videoUrl);

    if (videoTimeStart) this.videoPlayer.currentTime(videoTimeStart);

    // Don't run for first video
    if (this.isReady.video) {
      this.videoPlayer.play();
    }

    this.isReady.video = true;
  }

  private drawMarkerAtPoint(_point: { lat: number, lng: number }): void {
    if (!this.map) return;

    if (this.marker) {
      this.marker.setMap(null);
    }

    let point = new google.maps.LatLng(_point);

    this.marker = new google.maps.Marker({
      position: point,
      map: this.map,
    });

    this.marker.setMap(this.map);

    this.map.setCenter(point);
    this.map.setZoom(16);
  }

  private drawOneFlightPath(video: VideoModel, forceMarkerAtStart?: boolean): void {

    if (!video || !video.googleLineCoords) return;

    let flightPath = new google.maps.Polyline({
      path: video.googleLineCoords,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });

    flightPath.setMap(this.map);
    flightPath.addListener('click', this.onMapClick.bind(this));

    this.mapObjects.push(flightPath);
    if (!this.pointsCollection) this.pointsCollection = turf.featureCollection([...video.pointsCollection.features]);
    else this.pointsCollection = turf.featureCollection([...this.pointsCollection.features, ...video.pointsCollection.features]);

    if (!this.marker || forceMarkerAtStart) {
      this.drawMarkerAtPoint(video.googleLineCoords[0]);
    }

    this.zoomToExtent();
  }

  private onFocusChanged(srt: SRTModel, setVideoTime = true, nearest?: any): void {
    if (!srt || !this.isReady.map) return;

    if (this.composition && nearest && nearest.properties.videoId && nearest.properties.videoId !== this.video.id) {
      this.onCompositionVideoClick(this.composition.find(x => x.data.id === nearest.properties.videoId), srt.time);
      setTimeout(() => {
        this.onFocusChanged(srt, setVideoTime, nearest);
      }, 1000); // TODO form now is 1000 becouse video timeout is 500, we need to implement better solution here
      return;
    } else if (setVideoTime) {
      this.videoPlayer.currentTime(srt.time);
    }

    if (!nearest) {
      var point = turf.point([srt.latitude, srt.longitude]);
      nearest = turf.nearestPoint(point, this.video.pointsCollection);
    }

    let googlePoint = {
      lat: nearest.geometry.coordinates[0],
      lng: nearest.geometry.coordinates[1]
    };

    this.currentData = srt.data;
    this.maxCurrentPPM = this.currentData.map(item => Number(item.ppm)).reduce((acc, cur) => Math.max(acc, cur), 0);

    let index = this.tableData.findIndex(x => x.srtId === srt.id);
    if (index > 0) {
      this.tablePageIndex = Math.floor(index / this.tablePageSize);
    }

    if (!this.marker || this.marker.getPosition().lat() !== googlePoint.lat || this.marker.getPosition().lng() !== googlePoint.lng) {
      this.drawMarkerAtPoint(googlePoint);
    }
  }

  private setMapFilter(data: Array<any>, video?: VideoModel, skipRemove?: boolean,): void {

    // Remove all
    if (!skipRemove) this.filterMarkers.forEach(marker => { marker.setMap(null); });

    if (!data || data.length === 0 || !video) return;

    if (data.length > 200) {
      alert('Previše podataka na karti');
      // TODO
      return;
    }

    var image = {
      url: './assets/images/beachflag_1.png',
      size: new google.maps.Size(20, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 32)
    };

    data.forEach(element => {
      let srt = video.srts.find(item => item.id === element.srtId);
      let maxPPM = srt.data.map(item => Number(item.ppm)).reduce((acc, cur) => Math.max(acc, cur), 0).toString();

      let marker = new google.maps.Marker({
        map: this.map,
        position: {
          lat: srt.latitude,
          lng: srt.longitude
        },
        icon: image,
        title: maxPPM + ' PPM',
        label: maxPPM
      });

      marker.addListener('click', this.onMapClick.bind(this));
      this.filterMarkers.push(marker);
    });
  }

  public filterDataset(rawData: Array<any>): Array<any> {
    let data;

    if (this.filterMin && this.filterMax) {
      data = rawData.filter(item => (parseInt(item.ppm) >= this.filterMin) && (parseInt(item.ppm) <= this.filterMax));
    } else {
      if (this.filterMin) {
        data = rawData.filter(item => parseInt(item.ppm) >= this.filterMin);
      } else if (this.filterMax) {
        data = rawData.filter(item => parseInt(item.ppm) <= this.filterMax);
      }
    }

    return data;
  }

  public zoomToExtent(): void {
    var bbox = turf.bbox(this.pointsCollection);
    if (bbox) {
      var bounds = new google.maps.LatLngBounds();
      bounds.extend(new google.maps.LatLng(bbox[0], bbox[1]));
      bounds.extend(new google.maps.LatLng(bbox[2], bbox[3]));
      this.map && this.map.fitBounds(bounds);
    }
  }

  // ============ DOM METHODS ============

  public isCurrentRow(rowSrtTime: number): boolean {
    return (this.currentVideoTime || 1) === rowSrtTime;
  }

  // ============ DOM LISTENERS ============

  public onCompositionVideoClick(video: VideoCompositionInterface, videoTimeStart?: number): void {
    if (video.isLoading) return;

    this.video = null;

    this.composition.find(x => x.selected).selected = false;
    video.selected = true;

    this.clientId = video.clientId;
    this.projectId = video.projectId;
    this.flightId = video.flightId;
    this.videoId = video.videoId;

    this.init(true, false, videoTimeStart);
    this.onFilterSet(true);
  }

  public onVideoEnd(): void {
    if (!this.composition) return;

    let currentIndex = this.composition.findIndex(x => x.selected);
    let nextIndex = currentIndex + 1;
    if (nextIndex === this.composition.length) nextIndex = 0;

    if (this.composition[nextIndex].isLoading) return;

    this.onCompositionVideoClick(this.composition[nextIndex]);
  }

  public onVideoTimeUpdate(): void {
    this.currentVideoTime = parseInt(this.videoPlayer.currentTime());
    let srt = this.video ? this.video.srts.find(item => item.time === this.currentVideoTime) : null;
    this.onFocusChanged(srt, false);
  }

  public onDataClick(row: any): void {
    let srt = this.video.srts.find(item => item.id === row.srtId);
    this.onFocusChanged(srt);
  }

  public onMapClick(mapsMouseEvent): void {

    var point = turf.point([mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng()]);
    var nearest = turf.nearestPoint(point, this.pointsCollection);
    console.log(nearest);

    let video = this.video;
    if (this.composition) video = this.composition.find(x => x.data.id === nearest.properties.videoId).data;
    let srt = video.srts.find(x => x.id === nearest.properties.srtId);

    srt && this.onFocusChanged(srt, true, nearest);
  }

  public onFilterSet(dontZoomToExtent?: boolean): void {
    this.setMapFilter(null); // Clear map

    let data = this.filterDataset(this.tableDataAll);

    if (this.composition) {
      this.composition.forEach(item => {
        this.setMapFilter(this.filterDataset(item.data.tableDataAll), item.data, true);
      })
    } else {
      if (data) this.setMapFilter(data, this.video);
    }

    if (data) {
      this.tableData = data;
    } else {
      this.tableData = this.tableDataAll;
    }

    if (!dontZoomToExtent) this.zoomToExtent();
  }

  public onFilterClear(): void {
    this.tableData = this.tableDataAll;
    this.filterMin = null;
    this.filterMax = null;
    this.setMapFilter(null);
  }

  public onPlayClick(): void {
    this.videoPlayer && this.videoPlayer.play();
  }

  public onPauseClick(): void {
    this.videoPlayer && this.videoPlayer.pause();
  }

  public onForwardClick(): void {
    let current = this.videoPlayer.currentTime();
    let duration = this.videoPlayer.duration();
    let time = duration - current > 10 ? current + 10 : duration;
    this.videoPlayer.currentTime(time);
  }

  public onBackwardClick(): void {
    let current = this.videoPlayer.currentTime();
    let time = current > 10 ? current - 10 : 0;
    this.videoPlayer.currentTime(time);
  }

  public toggleVideoFullScreen(): void {
    this.videoFullScreen = !this.videoFullScreen;
  }

  public formatName(path: string): string {
    let names = path.split("/");
    return names[names.length - 1];
  }

  public formatTime(second: number): string {
    if (second >= 0) return moment().minutes(0).second(second).format('mm:ss');
    return "-";
  }

  public goToClients(): void {
    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS]);
  }

  public goToProjects(): void {
    if (this.clientId) {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS]);
    } else {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS]);
    }
  }

  public goToFlights(): void {
    if (this.clientId) {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS]);
    } else {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS]);
    }
  }

  public goToVideos(): void {
    if (this.clientId) {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS, this.flightId, MODULE_PATH_DRONE_VIDEOS]);
    } else {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS, this.flightId, MODULE_PATH_DRONE_VIDEOS]);
    }
  }

  public sort(sort: { key: string; value: string }): any {
    this.sorterKey = sort.key;
    this.sorterValue = sort.value;
    if (this.sorterValue && this.sorterKey) {
      this.tableData = this.tableData.sort((x, y) => this.sorterValue === 'ascend' ? x[this.sorterKey] > y[this.sorterKey] ? 1 : -1 : x[this.sorterKey] > y[this.sorterKey] ? -1 : 1);
      this.tableData = [...this.tableData];
    } else {
      this.onFilterSet();
    }
  }

  public onShowComments(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
    this.inputValue[id] = null;
  }

  public onSubmitComment(inputId: number): void {
    let model = new CommentModel({
      userId: this.srms.getCurrentUser().id,
      dataId: inputId,
      description: this.inputValue[inputId]
    });

    this.submitting = true;

    this.commentStore.create(model).subscribe(res => {
      if (res) {
        this.submitting = false;
        this.message.success(this.ts.instant('drone.playPage.commentSuccess'));
        let data = this.tableDataAll.find(x => x.id === res.dataId);
        data.comments.push(res);
        this.inputValue[inputId] = null;
      } else {
        this.message.error(this.ts.instant('drone.playPage.commentError'));
      }
    });
  }

  public onClearComment(id: number): void {
    this.inputValue[id] = null;
  }

  public timeConvert(value: number): string {
    return moment.unix(value).format("DD/MM/YYYY k:mm");
  }

  //source: https://github.com/phuoc-ng/html-dom/blob/master/demo/create-resizable-split-views/direction.html
  private resizeEvents(): void {
    const resizable = function(resizer) {
      const direction = resizer.getAttribute('data-direction') || 'horizontal';
      const prevSibling = resizer.previousElementSibling;
      const nextSibling = resizer.nextElementSibling;

      // The current position of mouse
      let x = 0;
      let y = 0;
      let prevSiblingHeight = 0;
      let prevSiblingWidth = 0;

      // Handle the mousedown event
      // that's triggered when user drags the resizer
      const mouseDownHandler = function(e) {
        // Get the current mouse position
        x = e.clientX;
        y = e.clientY;
        const rect = prevSibling.getBoundingClientRect();
        prevSiblingHeight = rect.height;
        prevSiblingWidth = rect.width;

        // Attach the listeners to `document`
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      const mouseMoveHandler = function(e) {
        // How far the mouse has been moved
        const dx = e.clientX - x;
        const dy = e.clientY - y;

        switch (direction) {
          case 'vertical':
            const h = (prevSiblingHeight + dy) * 100 / resizer.parentNode.getBoundingClientRect().height;
            prevSibling.style.height = `${h}%`;
            break;
          case 'horizontal':
          default:
            const w = (prevSiblingWidth + dx) * 100 / resizer.parentNode.getBoundingClientRect().width;
            prevSibling.style.width = `${w}%`;
            break;
        }

        const cursor = direction === 'horizontal' ? 'col-resize' : 'row-resize';
        resizer.style.cursor = cursor;
        document.body.style.cursor = cursor;

        prevSibling.style.userSelect = 'none';
        prevSibling.style.pointerEvents = 'none';

        nextSibling.style.userSelect = 'none';
        nextSibling.style.pointerEvents = 'none';
      };

      const mouseUpHandler = function() {
        resizer.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');

        prevSibling.style.removeProperty('user-select');
        prevSibling.style.removeProperty('pointer-events');

        nextSibling.style.removeProperty('user-select');
        nextSibling.style.removeProperty('pointer-events');

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      // Attach the handler
      resizer.addEventListener('mousedown', mouseDownHandler);
    };

    // Query all resizers
    document.querySelectorAll('.playPage__resizer').forEach(function(ele) {
      resizable(ele);
    });
  }

}

// === PRIVATE INTERFACE ===

export interface VideoCompositionInterface extends PlayPageRouteExtraStateInterface {
  selected: boolean;
  isLoading: boolean;
  data?: VideoModel;
}
