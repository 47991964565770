import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from "ng-zorro-antd";

import { Store, ModelService, SimpleRestManagerService } from 'src/app/common';
import { ProjectModel } from '../models/project.model';
import { ClientModel } from '../models/client.model';
import { CreateProjectModal } from '../modals/createProject/createProject.modal';
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, MODULE_PATH_DRONE_CLIENTS, MODULE_PATH_DRONE_FLIGHTS, MODULE_PATH_DRONE_PLAY } from '../drone.routes';
import { PlayPageRouteExtraStateInterface } from "src/app/common/interfaces/playPageRouteExtraState.interface";
import { CreateCompositionModal } from "../modals/createComposition/createComposition.modal";

@Component({
  selector: "projectsPage",
  templateUrl: "./projectsPage.html",
  styleUrls: ["./projectsPage.scss"]
})
export class ProjectsPageComponent implements OnInit {

  public clientId: number;

  public client: ClientModel;

  public storeClient: Store<ClientModel>;

  public store: Store<ProjectModel>;

  public tableData = [];

  constructor(private modelService: ModelService,
    private ts: TranslateService,
    public srms: SimpleRestManagerService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  public ngOnInit() {
    this.route.params.subscribe(params => {
      this.clientId = typeof (params['clientId']) === 'undefined' ? null : +params['clientId'];
      this.init()
    });
  }

  private init(): void {
    this.storeClient = this.modelService.getStore('ClientModel');

    this.store = this.modelService.getStore('ProjectModel');
    this.store.events.afterLoad.subscribe(() => {
      this.tableData = [...this.store.getData()];
    });

    this.store.removeSimpleFilter();
    this.store.cleanFilters();
    this.store.setSorter('name', 'ascend', false);

    if (this.clientId) {
      let filter = {
        attributeName: 'client.id',
        operator: '=',
        value: this.clientId
      };
      this.store.addFilter(<any>filter);

      this.storeClient.getDataById(this.clientId).subscribe(res => {
        this.client = res;
      });
    }

    this.store.fetch();
  }

  /**
   * On new project click
   */
  public onNewProjectClick(): void {
    const modalCreate: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createProjectModal.title.new'),
      nzContent: CreateProjectModal,
      nzComponentParams: {
        clientId: this.clientId
      }
    });
  }

  /**
   * On edit project click
   */
  public onEditProjectClick(project: ProjectModel): void {
    const modalEdit: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createProjectModal.title.edit'),
      nzContent: CreateProjectModal,
      nzComponentParams: {
        model: project
      }
    });
  }

  public deleteProject(project: ProjectModel): void {
    this.store.delete(project).subscribe(res => {
      if(res){
        this.message.success(this.ts.instant("drone.videosPage.successMsg.delete"));
      }else{
        this.message.error(this.ts.instant("drone.videosPage.errorMsg.delete"));
      }
    });
  }

  public goToClients(): void {
    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS]);
  }

  public goToFlights(id: number): void {
    if (this.clientId) {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS, id, MODULE_PATH_DRONE_FLIGHTS]);
    } else {
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, id, MODULE_PATH_DRONE_FLIGHTS]);
    }
  }

  public onCreateCompositionBtnClick(): void {
    this.modalService.create({
      nzTitle: this.ts.instant('drone.playWizardModal.activatonBtn'),
      nzContent: CreateCompositionModal,
      nzComponentParams: {
        clientId: this.clientId
      }
    });
  }

}
