export const MODULE_PATH_DRONE = 'drone';

export const MODULE_PATH_DRONE_CLIENTS = 'clients';

export const MODULE_PATH_DRONE_USERS = 'users';

export const MODULE_PATH_DRONE_PROJECTS = 'projects';

export const MODULE_PATH_DRONE_FLIGHTS = 'flights';

export const MODULE_PATH_DRONE_VIDEOS = 'videos';

export const MODULE_PATH_DRONE_PLAY = 'play';
