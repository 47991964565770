import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';

import { Store, ModelService, SimpleRestManagerService } from 'src/app/common';
import { UserModel } from '../models/user.model';
import { ClientModel } from '../models/client.model';
import { CreateUserModal } from '../modals/createUser/createUser.modal';
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS } from '../drone.routes';

@Component({
  selector: "usersPage",
  templateUrl: "./usersPage.html",
  styleUrls: ["./usersPage.scss"]
})
export class UsersPageComponent implements OnInit {

  public clientId: number;

  public client: ClientModel;

  public storeClient: Store<ClientModel>;

  public store: Store<UserModel>;

  public tableData = [];

  constructor(private modelService: ModelService,
              private ts: TranslateService,
              public srms: SimpleRestManagerService,
              private modalService: NzModalService,
              private router: Router,
              private route: ActivatedRoute){
  }

  public ngOnInit(){
    this.route.params.subscribe(params => {
      this.clientId = typeof(params['clientId']) === 'undefined' ? null : +params['clientId'];
      this.init()
    });
  }

  private init(): void{
    this.storeClient = this.modelService.getStore('ClientModel');
    this.storeClient.getDataById(this.clientId).subscribe(res => {
      this.client = res;
    });

    this.store = this.modelService.getStore('UserModel');
    this.store.events.afterLoad.subscribe(() => {
    	this.tableData = [...this.store.getData()].filter(item => item.client.id === this.clientId);
    });

		this.store.removeSimpleFilter();
    this.store.cleanFilters();
		this.store.setSorter('email', 'ascend', false);

    this.store.fetch();
  }

  /**
	 * On new user click
	 */
	public onNewUserClick(): void {
    const modalCreate: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createUserModal.title.new'),
      nzContent: CreateUserModal,
      nzComponentParams: {
        clientId: this.clientId
      }
    });
  }

  /**
	 * On edit user click
	 */
	public onEditUserClick(user: UserModel): void {
    const modalEdit: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createUserModal.title.edit'),
      nzContent: CreateUserModal,
      nzComponentParams: {
        model: user
      }
    });
  }

  public goToClients(): void {
    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS]);
  }

}
