import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef, NzFormatEmitEvent, NzTreeNodeOptions } from 'ng-zorro-antd';

import { Store, ModelService } from 'src/app/common';
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_PLAY } from '../../drone.routes';
import { FlightModel } from '../../models/flight.model';
import { ProjectModel } from '../../models/project.model';
import { VideoModel } from '../../models/video.model';

@Component({
  selector: "createComposition",
  templateUrl: "./createComposition.html"
})
export class CreateCompositionModal implements OnInit {

  public clientId?: number;
  public projectId?: number;
  public flightId?: number;

  public defaultCheckedKeys = [];
  public defaultSelectedKeys = [];
  public defaultExpandedKeys = [];

  public nodes: Array<NzTreeNodeOptions> = [];

  public isLoading: boolean = false;

  constructor(private modal: NzModalRef,
    private modelService: ModelService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.isLoading = true;
    if (this.flightId) this.loadVideos();
    else if (this.projectId) this.loadFlights();
    else this.loadProjects();
  }

  private loadProjects(resolve?: any): void {
    let store = this.modelService.getStore('ProjectModel');
    store.removeSimpleFilter();
    store.cleanFilters();
    store.setPerPage(99999);

    if (this.clientId) {
      let filter = {
        attributeName: 'client.id',
        operator: '=',
        value: this.clientId
      };
      store.addFilter(<any>filter);
    }

    let transformFn = (p: ProjectModel) => ({ key: `${p.id}`, title: `${p.name} - ${p.description}`, selectable: false, levelNodeType: 'project', levelNodeIds: [p.id] });

    store.fetch().subscribe(resp => {
      let data = store.getData();
      let nodes = [];
      data.forEach((x: any) => {
        nodes.push(transformFn(x));
      });

      if (resolve) {
        resolve(nodes);
      } else {
        this.nodes = nodes;
        this.isLoading = false;
      }
    });
  }

  private loadFlights(resolve?: any, parentIds?: any): void {
    let store = this.modelService.getStore('FlightModel');
    store.removeSimpleFilter();
    store.cleanFilters();
    store.setPerPage(99999);

    store.addFilter({
      attributeName: 'project.id',
      operator: '=',
      value: parentIds ? parentIds[parentIds.length - 1] : this.projectId
    });

    let parentPrefix = parentIds ? (parentIds).join('-') : '';

    let transformFn = (f: FlightModel) => ({
      key: parentIds ? `${[parentPrefix, f.id].join('-')}` : `${f.id}`,
      title: `${f.name}`,
      selectable: false,
      checked: !!this.defaultCheckedKeys.find(x => x === parentPrefix),
      levelNodeType: 'flight',
      levelNodeIds: [...(parentIds || []), f.id]
    });

    store.fetch().subscribe(resp => {
      let data = store.getData();
      let nodes = [];
      data.forEach((x: any) => {
        nodes.push(transformFn(x));
      });

      if (resolve) {
        resolve(nodes);
      } else {
        this.nodes = nodes;
        this.isLoading = false;
      }
    });
  }

  private loadVideos(resolve?: any, parentIds?: any): void {
    let store = this.modelService.getStore('VideoModel');
    store.removeSimpleFilter();
    store.cleanFilters();
    store.setPerPage(99999);

    store.addFilter({
      attributeName: 'flight.id',
      operator: '=',
      value: parentIds ? parentIds[parentIds.length - 1] : this.flightId
    });
    store.setSorter('path', 'ascend', false);

    let parentPrefix = parentIds ? (parentIds).join('-') : '';

    let transformFn = (v: VideoModel) => {
      let names = v.path.split("/");
      return {
        key: parentIds ? `${[parentPrefix, v.id].join('-')}` : `${v.id}`,
        title: `${names[names.length - 1]}`,
        selectable: false,
        isLeaf: true,
        checked: !!this.defaultCheckedKeys.find(x => x === parentPrefix),
        levelNodeType: 'video',
        levelNodeIds: [...(parentIds || []), v.id]
      };
    };

    store.fetch().subscribe(resp => {
      let data = store.getData();
      let nodes = [];
      data.forEach((x: any) => {
        nodes.push(transformFn(x));
      });

      if (resolve) {
        resolve(nodes);
      } else {
        this.nodes = nodes;
        this.isLoading = false;
      }
    });
  }

  private loadNode(event: NzFormatEmitEvent): Promise<NzTreeNodeOptions[]> {
    return new Promise(resolve => {
      if (event.node.origin.levelNodeType === 'project') {
        this.loadFlights(resolve, event.node.origin.levelNodeIds);
      } else if (event.node.origin.levelNodeType === 'flight') {
        this.loadVideos(resolve, event.node.origin.levelNodeIds);
      }
    });
  }

  // === DOM LISTENERS ===

  public nzEvent(event: NzFormatEmitEvent): void {
    event.eventName === 'expand' && this.expand(event);
    event.eventName === 'check' && this.check(event);
  }

  public expand(event: NzFormatEmitEvent): void {
    const node = event.node;
    if (node.getChildren().length === 0 && node.isExpanded) {
      this.loadNode(event).then(data => {
        node.addChildren(data);
      });
    }
  }

  public check(event: NzFormatEmitEvent): void {
    this.defaultCheckedKeys = event.keys;
    const node = event.node;
    if (node.origin.levelNodeType === 'flight' && node.getChildren().length === 0) {
      let resolve = (data) => {
        node.addChildren(data);
      };
      this.loadVideos(resolve, node.origin.levelNodeIds);
    }
  }

  private destroyModal(success: boolean): void {
    this.modal.destroy({ data: success });
  }

  public onCancelClick(): void {
    this.destroyModal(false);
  }

  public onSubmitClick(): void {
    let videos = [];

    // Find video index - needed to sort videos according to user selections
    let findIndex = (ids: Array<string>) => {
      let key1 = this.defaultCheckedKeys.findIndex(x => x === ids.join('-'));
      if (key1 > -1) return key1;

      ids.pop();
      if (ids.length === 0) return -1;
      let key2 = this.defaultCheckedKeys.findIndex(x => x === ids.join('-'));
      if (key2 > -1) return key2;

      ids.pop();
      if (ids.length === 0) return -1;
      let key3 = this.defaultCheckedKeys.findIndex(x => x === ids.join('-'));
      return key3;
    };

    // Prepare video data
    let mapVideoNode = (node: NzTreeNodeOptions) => {
      let key = node.key;

      let projectId: any = this.projectId;
      let flightId: any = this.flightId;
      let videoId: any = null;

      let keyParts = key.split('-');
      if (keyParts.length === 3) {
        projectId = keyParts[0];
        flightId = keyParts[1];
        videoId = keyParts[2];
      } else if (keyParts.length === 2) {
        flightId = keyParts[0];
        videoId = keyParts[1];
      } else {
        videoId = keyParts[0];
      }

      return {
        clientId: null,
        projectId: parseInt(projectId),
        flightId: parseInt(flightId),
        videoId: parseInt(videoId),
        videoName: node.title,
        index: findIndex(keyParts)
      };
    };

    // Go through the tree and find selected nodes
    let recursion = (node: NzTreeNodeOptions) => {
      if (node.isLeaf) {
        node.checked && videos.push(mapVideoNode(node));
      } else if (node.children && node.children.length > 0) {
        node.children.forEach(node => recursion(node));
      }
    };
    this.nodes.forEach(node => recursion(node));

    videos.sort((x,y) => x.index - y.index);

    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PLAY], { state: { videos } });
    this.destroyModal(false);
  }

}
