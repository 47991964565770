import { NgModule } from '@angular/core';
import { CommonModule, STORE_CONFIG_PROVIDER } from '../common';
import { AuthenticationModule } from '../authentication/authentication.module';
import { DroneRouting } from './drone.routing';

import { storeServiceConfig } from './drone.models';

import { MainContainerComponent } from './mainContainer/mainContainer.component';
import { ClientsPageComponent } from './clientsPage/clientsPage.component';
import { UsersPageComponent } from './usersPage/usersPage.component';
import { ProjectsPageComponent } from './projectsPage/projectsPage.component';
import { FlightsPageComponent } from './flightsPage/flightsPage.component';
import { VideosPageComponent } from './videosPage/videosPage.component';
import { PlayPageComponent } from './playPage/playPage.component';

// modals
import { CreateClientModal } from './modals/createClient/createClient.modal';
import { CreateUserModal } from './modals/createUser/createUser.modal';
import { CreateProjectModal } from './modals/createProject/createProject.modal';
import { CreateFlightModal } from './modals/createFlight/createFlight.modal';
import { CreateCompositionModal } from './modals/createComposition/createComposition.modal';

@NgModule({
  imports: [
    CommonModule,
    AuthenticationModule,
    DroneRouting
  ],

  declarations: [
    MainContainerComponent,
    ClientsPageComponent,
    UsersPageComponent,
    ProjectsPageComponent,
    FlightsPageComponent,
    VideosPageComponent,
    PlayPageComponent,

    CreateClientModal,
    CreateUserModal,
    CreateProjectModal,
    CreateFlightModal,
    CreateCompositionModal
  ],

  entryComponents: [
    CreateClientModal,
    CreateUserModal,
    CreateProjectModal,
    CreateFlightModal,
    CreateCompositionModal
  ],

  providers: [
    { provide: STORE_CONFIG_PROVIDER, useValue: storeServiceConfig, multi: true }
  ]
})
export class DroneModule { }
