import { AbstractModel, ModelType } from "./abstract.model";

/**
 * Manage and controll collection of Models
 */
export class ModelCollection<T extends AbstractModel> {

  /**
   * Array of models in collection
   */
  private _array: T[] = [];

  /**
   * Type of data stored in collection
   */
  private _modelType: ModelType;

  /**
   * Sequence for new objects
   */
  private newRecordSequence: number = -1;

  constructor(modelType: ModelType, data?: Array<Object>) {
    this._modelType  = modelType;
    this.deserialize(data);
  }

  /**
   * Add new model to a collection
   * @param model <T> Object to add
   */
  public add(model: T): void {
    this.update(model);
  }

  /**
   * Update model to a collection
   * @param model <T> Object to add
   */
  public update(model: T): void {
    if (!model.getPk()) {
      model.setPk(--this.newRecordSequence);
      this._array.push(model);
    } else {
      let index = this._array.findIndex(element => element.getPk() == model.getPk());
      if(index < 0) {
        this._array.push(model);
      } else {
        this._array[index] = model;
      }
    }
  }

  /**
   * Translate REST object to Model
   * @param data <Object> Object for serialization
   */
  public deserialize(data: Array<Object>): void {
    if(!data) return;
    this.clean();
    data.forEach(item => {
      let model = <T>new this._modelType(item);
      this._array.push(model);
    });
  }

  /**
   * Translate Model to REST object
   * @returns <Object> Serialized object
   */
  public serialize(): Array<Object> {
    let object = [];
    this._array.forEach(element => {
      object.push(element.serialize());
    });
    return object;
  }

  /**
   * Get the collection item as an array
   * @returns <Array<T>> Array of models
   */
  public getArray(): Array<T> {
    return this._array.slice(0);
  }

  /**
   * Get numbers of elements in the collection
   * @returns <number> Number of element
   */
  public getLength(): number {
    return this._array.length;
  }

  /**
   * Alias for: getLength()
   * Get numbers of elements in the collection
   * @returns <number> Number of element
   */
  public get length(): number {
    return this.getLength();
  }

  /**
   * Get element from array
   * @param pk <number Primary key value
   * @returns <T> Model
   */
  public getDataByID(pk: number): T {
    return this._array.find(element => element.getPk() == pk);
  }

  /**
   * Remove all items from collection
   */
  public clean(): void {
    this._array = [];
  }

  /**
   * Get an item from model
   */
  public getItem(key: string): any {
    return this[key];
  }

  /**
   * Get an item from model
   */
  public setItem(key: string, value: any): void {
    this[key] = value;
  }
}
