import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UploadFile, NzMessageService, UploadXHRArgs, NzModalService } from 'ng-zorro-antd';

import { Store, ModelService, SimpleRestManagerService } from 'src/app/common';
import { VideoModel } from '../models/video.model';
import { CSVModel } from '../models/csv.model';
import { FlightModel } from '../models/flight.model';
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, MODULE_PATH_DRONE_PROJECTS, MODULE_PATH_DRONE_FLIGHTS, MODULE_PATH_DRONE_VIDEOS, MODULE_PATH_DRONE_PLAY } from '../drone.routes';
import { environment } from 'src/environments/environment';
import { PlayPageRouteExtraStateInterface } from "src/app/common/interfaces/playPageRouteExtraState.interface";
import { CreateCompositionModal } from "../modals/createComposition/createComposition.modal";

@Component({
  selector: "videosPage",
  templateUrl: "./videosPage.html",
  styleUrls: ["./videosPage.scss"]
})
export class VideosPageComponent implements OnInit {

  public clientId: number;

  public projectId: number;

  public flightId: number;

  public flight: FlightModel;

  public storeFlight: Store<FlightModel>;

  public storeVideo: Store<VideoModel>;

  public tableDataVideo = [];

  public storeCSV: Store<CSVModel>;

  public tableDataCSV = [];

  public uploadVideoUrl: string;

  public uploadVideoLoading: boolean;

  public uploadDataUrl: string;

  public uploadDataLoading: boolean;

  public uploadSrtUrl: string;

  public uploadSrtLoading: boolean;

  @ViewChild("progressMsg", {static: true})
  private progressMsgTemplate: TemplateRef<any>;

  private progressMsgId: string;

  public uploads: Array<{uid:string, percent: number}> = [];

  constructor(private modelService: ModelService,
              private ts: TranslateService,
              public srms: SimpleRestManagerService,
              private message: NzMessageService,
              private modalService: NzModalService,
              private router: Router,
              private route: ActivatedRoute){
  }

  public ngOnInit(){
    this.route.params.subscribe(params => {
      this.clientId = typeof(params['clientId']) === 'undefined' ? null : +params['clientId'];
      this.projectId = typeof(params['projectId']) === 'undefined' ? null : +params['projectId'];
      this.flightId = typeof(params['flightId']) === 'undefined' ? null : +params['flightId'];
      this.init()
    });
  }

  public init(){
    this.storeFlight = this.modelService.getStore('FlightModel');
    this.storeFlight.getDataById(this.flightId).subscribe(res => {
      this.flight = res;
    });

    this.storeVideo = this.modelService.getStore('VideoModel');
    this.storeVideo.events.afterLoad.subscribe(() => {
      this.tableDataVideo = [...this.storeVideo.getData()];
    });

    this.storeVideo.removeSimpleFilter();
    this.storeVideo.cleanFilters();

    if(this.flightId){
			let filter = {
        attributeName: 'flight.id',
        operator: '=',
        value: this.flightId
      };
      this.storeVideo.addFilter(<any>filter);
		}
    this.storeVideo.setSorter('path', 'ascend', false);
    this.storeVideo.fetch();

    this.storeCSV = this.modelService.getStore('CSVModel');
    this.storeCSV.events.afterLoad.subscribe(() => {
      this.tableDataCSV = [...this.storeCSV.getData()];
    });

    this.storeCSV.removeSimpleFilter();
    this.storeCSV.cleanFilters();

    if(this.flightId){
			let filter = {
        attributeName: 'flight.id',
        operator: '=',
        value: this.flightId
      };
      this.storeCSV.addFilter(<any>filter);
		}
    this.storeCSV.setSorter('path', 'ascend', false);
    this.storeCSV.fetch();

    this.uploadVideoUrl = [environment.serviceUrl, "video/uploadVideo", this.flightId].join("/");
    this.uploadDataUrl = [environment.serviceUrl, "data/uploadData", this.flightId].join("/");
    this.uploadSrtUrl = [environment.serviceUrl, "srt/uploadSrt"].join("/");
  }

  public onUploadVideo(info: { file: UploadFile, type: string }): void {
    if(info.type === 'start'){
      this.uploads.push({
        uid: info.file.uid,
        percent: 0
      });
    }else{
      let index = this.uploads.findIndex(item => item.uid === info.file.uid);
      switch (info.file.status) {
        case 'uploading':
          this.uploads[index].percent = Math.floor(info.file.percent);
          if (!this.uploadVideoLoading) this.createProgressMessage();
          this.uploadVideoLoading = true;
          break;
        case 'done':
          this.uploads.splice(index,1);
          if(!this.uploads.length){
            this.uploadVideoLoading = false;
            this.removeProgressMessage();
          }
          this.storeVideo.fetch();
          this.message.success(this.ts.instant("drone.videosPage.successMsg.video"));
          break;
        case 'error':
          this.uploads.splice(index,1);
          if(!this.uploads.length){
            this.uploadVideoLoading = false;
            this.removeProgressMessage();
          }
          this.message.error(this.ts.instant("drone.videosPage.errorMsg.video"));
          break;
      }
    }
  }

  public onUploadData(info: { file: UploadFile, type: string }): void {
    if(info.type === 'start'){
      this.uploads.push({
        uid: info.file.uid,
        percent: 0
      });
    }else{
      let index = this.uploads.findIndex(item => item.uid === info.file.uid);
      switch (info.file.status) {
        case 'uploading':
          this.uploads[index].percent = Math.floor(info.file.percent);
          if (!this.uploadDataLoading) this.createProgressMessage();
          this.uploadDataLoading = true;
          break;
        case 'done':
          this.uploads.splice(index,1);
          if(!this.uploads.length){
            this.uploadDataLoading = false;
            this.removeProgressMessage();
          }
          this.storeCSV.fetch();
          this.message.success(this.ts.instant("drone.videosPage.successMsg.data"));
          break;
        case 'error':
          this.uploads.splice(index,1);
          if(!this.uploads.length){
            this.uploadDataLoading = false;
            this.removeProgressMessage();
          }
          this.message.error(this.ts.instant("drone.videosPage.errorMsg.data"));
          break;
      }
    }
  }

  public onUploadSrt(info: { file: UploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploadSrtLoading = true;
        break;
      case 'done':
        this.uploadSrtLoading = false;
        this.storeVideo.fetch();
        this.message.success(this.ts.instant("drone.videosPage.successMsg.srt"));
        break;
      case 'error':
        this.uploadSrtLoading = false;
        this.message.error(this.ts.instant("drone.videosPage.errorMsg.srt"));
        break;
    }
  }

  public deleteVideo(video: VideoModel): void {
    this.storeVideo.delete(video).subscribe(res => {
      if(res){
        this.message.success(this.ts.instant("drone.videosPage.successMsg.delete"));
      }else{
        this.message.error(this.ts.instant("drone.videosPage.errorMsg.delete"));
      }
    });
  }

  public deleteCSV(csv: CSVModel): void {
    this.storeCSV.delete(csv).subscribe(res => {
      if(res){
        this.message.success(this.ts.instant("drone.videosPage.successMsg.delete"));
      }else{
        this.message.error(this.ts.instant("drone.videosPage.errorMsg.delete"));
      }
    });
  }

  private createProgressMessage(): void {
    this.progressMsgId = this.message.info(this.progressMsgTemplate, { nzDuration: 0 }).messageId;
  }

  private removeProgressMessage(): void {
    this.message.remove(this.progressMsgId);
  }

  public formatName(path: string): string{
    let names = path.split("/");

    return names[names.length-1];
  }

  public goToClients(): void {
    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS]);
  }

  public goToProjects(): void {
    if(this.clientId){
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS]);
    }else{
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS]);
    }
  }

  public goToFlights(): void {
    if(this.clientId){
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS]);
    }else{
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS]);
    }
  }

  public goToPlay(id: number): void {
    if(this.clientId){
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS, this.flightId, MODULE_PATH_DRONE_VIDEOS, id, MODULE_PATH_DRONE_PLAY]);
    }else{
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS, this.flightId, MODULE_PATH_DRONE_VIDEOS, id, MODULE_PATH_DRONE_PLAY]);
    }
  }

  public onCreateCompositionBtnClick(): void {
    this.modalService.create({
      nzTitle: this.ts.instant('drone.playWizardModal.activatonBtn'),
      nzContent: CreateCompositionModal,
      nzComponentParams: {
        clientId: this.clientId,
        projectId: this.projectId,
        flightId: this.flightId
      }
    });
  }

}
