import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';

import { Store, ModelService, SimpleRestManagerService } from 'src/app/common';
import { ClientModel } from '../models/client.model';
import { CreateClientModal } from '../modals/createClient/createClient.modal';
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, MODULE_PATH_DRONE_USERS, MODULE_PATH_DRONE_PROJECTS } from '../drone.routes';

@Component({
  selector: "clientsPage",
  templateUrl: "./clientsPage.html",
  styleUrls: ["./clientsPage.scss"]
})
export class ClientsPageComponent implements OnInit {

  public store: Store<ClientModel>;

  public tableData = [];

  constructor(private modelService: ModelService,
              private ts: TranslateService,
              public srms: SimpleRestManagerService,
              private modalService: NzModalService,
              private router: Router){
  }

  public ngOnInit(){
    this.store = this.modelService.getStore('ClientModel');
    this.store.events.afterLoad.subscribe(() => {
      this.tableData = [...this.store.getData()];
    });

    this.store.removeSimpleFilter();
    this.store.cleanFilters();
    this.store.setSorter('clientName', 'ascend', false);

    this.store.fetch();
  }

  /**
	 * On new client click
	 */
	public onNewClientClick(): void {
    const modalCreate: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createClientModal.title.new'),
      nzContent: CreateClientModal
    });
  }

  /**
	 * On edit client click
	 */
	public onEditClientClick(client: ClientModel): void {
    const modalEdit: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createClientModal.title.edit'),
      nzContent: CreateClientModal,
      nzComponentParams: {
        model: client
      }
    });
  }

  public goToUsers(id: number): void {
    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, id, MODULE_PATH_DRONE_USERS]);
  }

  public goToProjects(id: number): void {
    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, id, MODULE_PATH_DRONE_PROJECTS]);
  }

}
