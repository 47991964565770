import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: [' :host { width: inherit; height: inherit; } ']
})
export class MainComponent implements OnInit {
  
  ngOnInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    document.body.style.height = event.target.innerHeight + "px";
  }

}
