import {
  AbstractModel,
  Id,
  Column
} from "../../common";

/**
 * Model for a Client REST object
 */
export class ClientModel extends AbstractModel {

  @Id
  @Column()
  public id: number;

  @Column()
  public clientName: string;

}
