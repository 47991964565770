import {
  AbstractModel,
  Id,
  Column,
  OneToOne
} from "../../common";
import { FlightModel } from './flight.model';

/**
 * Model for a CSV REST object
 */
export class CSVModel extends AbstractModel {

  @Id
  @Column()
  public id: number;

  @Column()
  public path: string;

  @OneToOne(FlightModel)
  public flight?: FlightModel;

}
