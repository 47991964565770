import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";

import { SimpleRestManagerService, UtilService } from "../../common";
import { MODULE_PATH_DRONE } from "../../drone";
import { MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN, MODULE_PATH_AUTH_FORGOT_PASSWORD} from "../index";

@Component({
  selector: "register",
  templateUrl: "./register.html",
  styleUrls: ["./register.scss"]
})
export class RegisterComponent {

  public isLoading: boolean = false;

  public form: FormGroup;

  constructor(private srms: SimpleRestManagerService,
              public util: UtilService,
              private ts: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder) {

    this.createForm();
  }

  /**
	 * Create register form
	 */
	private createForm(): void {
		this.form = this.fb.group({
			email: [ null, [Validators.required, Validators.email]],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
			password: [null, Validators.required]
    });
  }

  // ========== DOM LISTENERS ==========

	/**
	 * On register button click
	 */
	public onRegisterBtnClick(): void {


  }

  /**
	 * Go to login screen
	 */
	public goToLogin(): void {
    this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN]);
  }

  /**
	 * Go to forgot password screen
	 */
	public goToForgotPassword(): void {
    this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_FORGOT_PASSWORD]);
  }

}
