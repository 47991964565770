import { 
    Id,
    Column,
    OneToOne,
    AbstractModel } from 'src/app/common';

export class CommentModel extends AbstractModel {

    @Id
    @Column()
    public userId: number;

    @Column()
    public dataId: number;

    @Column()
    public description: string;

}
