import { Injectable, Inject, Optional, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import {
  AbstractModel,
  ModelType
} from '../models/abstract.model';
import { ModelCollection } from '../models/modelCollection';
import { ReplaySubject } from 'rxjs';
import { HttpGetRequestParamsInterface } from '../abstract/service.abstract.service';
import { Subscriber } from 'rxjs';
import { ResponseInterface } from '../interfaces/response.interface';
import { ResponsePaginateInterface } from '../interfaces/responsePaginate.interface';

@Injectable()
export class ModelService {

  /**
   * Stores dictionary
   */
  private stores: { [storeName: string]: Store<any>; } = {};

  constructor(protected http: HttpClient,
    @Optional() @Inject('StoreServiceModelsConfig') config: Array<StoreConfigInterface>) {
    this.initConfig(config);
  }

  /**
   * Init service context
   * @param config <Array<StoreConfigInterface>> Store config
   */
  private initConfig(config: Array<StoreConfigInterface>): void {
    if (config) {
      config.forEach((s) => {
        s.storeDescriptors.forEach(c => {
          this.registerStore(c.modelType, c.storeName, c.url);
        });
      });
    }
  }

  /**
   * Register new store
   * @param modelType <ModelType> Model type
   * @param storeName <string> Store name
   * @param url <string> Store resource URL
   * @returns <Store<T>> Store object
   */
  public registerStore<T extends AbstractModel>(modelType: ModelType, storeName: string, url: string): Store<T> {
    let store = new Store<T>(modelType, url, this.http);
    this.stores[storeName] = store;
    return store;
  }

  /**
   * Get store object
   * @param storeName <string> Store name
   * @returns <Store<T>> Store object
   */
  public getStore<T extends AbstractModel>(storeName: string): Store<T> {
    return this.stores[storeName];
  }

}

/**
 * Store
 */
export class Store<T extends AbstractModel> {

  /**
   * Store events
   */
  public events: {
    beforeLoad: EventEmitter<any>;
    afterLoad: EventEmitter<any>;
    filterChanged: EventEmitter<any>;
  };

  /**
   * Is store in loading process
   */
  public isLoading;

  /**
   * Collection of data
   */
  private collection: ModelCollection<T>;

  /**
   * Filter list
   */
  private remoteFilter: Array<FilterItemInterface>;

  /**
   * Parameters list
   */
  private remoteParams: Array<UrlParamInterface>;

  /**
   *
   */
  public fetchOptions: StoreFetchOptionsInterface = {};

  /**
   * Statistics
   */
  // public statistics: any = {};

  constructor(private modelType: ModelType,
    private url: string,
    private http: HttpClient) {
    this.init();
  }

  /**
   * Init Store context
   */
  private init() {

    this.events = {
      beforeLoad: new EventEmitter(),
      afterLoad: new EventEmitter(),
      filterChanged: new EventEmitter()
    };

    this.collection = new ModelCollection(this.modelType);
    this.remoteFilter = new Array<FilterItemInterface>();
    this.remoteParams = new Array<UrlParamInterface>();
  }

  // ============== ACCESS METHODS ==============

  /**
   * Get data in array
	 * @returns <Array<T>> Array of objects
   */
  public getData(): Array<T> {
    return this.collection.getArray();
  }

  /**
   * Get data instant by id
   * If data is not loaded null will be returned
   * @param pk <number> Primary key value
   * @returns <T> Model
   */
  public getDataInstantById(pk: number): T {
    return this.collection.getDataByID(pk);
  }

  /**
   * Get data by id
   * If data is not loaded null will be returned
   * @param id <number> Primary key value
   * @returns <T> Model
   */
  public getDataById(id: number, fetch: boolean = false): Observable<T> {

    let fn = (o: Subscriber<T>) => {

      let resolve = (data: T) => {
        o.next(data);
        o.complete();
      };

      let data = this.collection.getDataByID(id);

      if(!data || fetch) {
        this.isLoading = true;
        this.http.get(this.getApiUrl() + '/' + id).subscribe((response: ResponseInterface) => {
          let model = <any>new this.modelType(response.data[0]);
          data ? this.collection.update(model) : this.collection.add(model);
          this.isLoading = false;
          resolve(model);
        }, error => {
          resolve(null);
        });

      } else {
        resolve(data);
      }
    };

    return Observable.create(fn);
  }

  /**
   * Change store page
   */
  public changePage(pageNumber: number, fetch: boolean = true): void {
    this.fetchOptions.page = pageNumber-1;
    if(fetch){
      this.fetch();
    }
  }

  /**
   * Set store sorter
   * @param sortName <string> Attribute name
   * @param value <string> Sort direction
   */
  public setSorter(sortName: string, value: 'ascend' | 'descend', fetch: boolean = true): void {
    if(value) {
      let direction = value === "ascend" ? "asc" : "desc";
			this.fetchOptions.sort = [sortName, direction].join(",");
		} else {
			delete this.fetchOptions.sort;
    }

    if(fetch){
      this.fetch();
    }
  }

  /**
   * Filter store data
   * @param value <string>
   */
  public setSimpleFilter(value: string): void {
    if (value && value.length > 0) {
      this.fetchOptions.page = 1;
      this.fetchOptions.search = value;
    } else {
      delete this.fetchOptions.search;
    }
    this.events.filterChanged.emit();
    this.changePage(1, false);
    this.fetch();
  }

  /**
   * Remove filter store data
   * @param value <string>
   */
  public removeSimpleFilter(): void {
    this.events.filterChanged.emit();
    this.changePage(1, false);
    delete this.fetchOptions.search;
  }

  /**
   * Get an item from model
   */
  public getItem(key: string): any {
    return this[key];
  }

  /**
   * Get an item from model
   */
  public setItem(key: string, value: any): void {
    this[key] = value;
  }

  /**
   * Add filter
   */
  public addFilter(filter: FilterItemInterface): void {
		this.remoteFilter.push(filter);
		this.serializeFilter();
    this.events.filterChanged.emit();
    this.changePage(1, false);
  }

  /**
   * Remove filter
   */
  public removeFilter(filter: FilterItemInterface): void {
    let index = this.remoteFilter.indexOf(filter);
    if (index > -1) {
      this.remoteFilter.splice(index, 1);
    }
		this.serializeFilter();
    this.events.filterChanged.emit();
    this.changePage(1, false);
  }

  /**
   * Remove filter by atribute name
   */
  public removeFilterByAttributeName(attributeName: string): void {
    let filters = this.remoteFilter.filter(filter => filter.attributeName !== attributeName);
    this.remoteFilter = filters;
		this.serializeFilter();
    this.events.filterChanged.emit();
    this.changePage(1, false);
  }

  /**
   * Clean filters
   */
  public cleanFilters(): void {
		this.remoteFilter = new Array<FilterItemInterface>();
		this.serializeFilter();
    this.events.filterChanged.emit();
    this.changePage(1, false);
  }

  /**
   * Set perPage number
   */
  public setPerPage(perPage: number): void {
    this.fetchOptions.size = perPage;
  }

  /**
   * Remove perPage number
   */
  public removePerPage(): void {
    this.fetchOptions.size = null;
  }

  /**
   * Add url parameter
   */
  public addUrlParam(param: UrlParamInterface): void {
    this.remoteParams.push(param);
    this.serializeUrlParams();
  }

  /**
   * Remove url parameter by key
   */
  public removeUrlParamByKey(key: string): void {
    let filters = this.remoteParams.filter(param => param.key !== key);
    this.remoteParams = filters;
		this.serializeUrlParams();
  }

  /**
   * Clean url parameters
   */
  public cleanUrlParam(): void {
    this.remoteParams = new Array<UrlParamInterface>();
		this.serializeUrlParams();
  }

  // ============== REST METHODS ==============

  /**
   * Fetch data from a server
	 * @returns <Observable> Observable object
   */
  public fetch(): ReplaySubject<any> {

    let subject: ReplaySubject<any> = new ReplaySubject(1);

    this.isLoading = true;
    this.events.beforeLoad.emit();

    this.http.get(this.getApiUrl(this.fetchOptions)).subscribe((response: ResponsePaginateInterface<T>) => {

      this.deserializePagginator(response.data[0]);
      this.collection.deserialize(response.data[0].content);

      this.isLoading = false;
      this.events.afterLoad.emit();

      subject.next(true);
      subject.complete();
    }, err => {
      this.isLoading = false;

      subject.next(false);
      subject.complete();
    });

    return subject;
  }

  /**
   * Create new data on server
	 * @returns <Observable> Observable object
   */
  public create(model: T): ReplaySubject<any> {

    let subject: ReplaySubject<any> = new ReplaySubject(1);

    this.isLoading = true;
    let payload = model.serialize();

    this.http.post(this.getApiUrl(), payload).subscribe((response: ResponseInterface) => {
      this.isLoading = false;
      this.fetch();

      subject.next(response.data[0]);
      subject.complete();
    }, err => {
      this.isLoading = false;

      subject.next(null);
      subject.complete();
    });

    return subject;
  }

  /**
   * Update data on server
	 * @returns <Observable> Observable object
   */
  public update(model: T): ReplaySubject<any> {

    let subject: ReplaySubject<any> = new ReplaySubject(1);

    this.isLoading = true;
    let payload = model.serialize();

    this.http.put(this.getApiUrl() + '/' + (<any>payload).id, payload).subscribe((response: ResponseInterface) => {
      this.isLoading = false;
      this.fetch();

      subject.next(true);
      subject.complete();
    }, err => {
      this.isLoading = false;

      subject.next(false);
      subject.complete();
    });

    return subject;
  }

  /**
   * Delete data on server
	 * @returns <Observable> Observable object
   */
  public delete(model: T): ReplaySubject<any> {

    let subject: ReplaySubject<any> = new ReplaySubject(1);

    this.isLoading = true;

    this.http.delete(this.getApiUrl() + '/' + (<any>model).id).subscribe((response: ResponseInterface) => {
      this.isLoading = false;
      this.fetch();

      subject.next(true);
      subject.complete();
    }, err => {
      this.isLoading = false;

      subject.next(false);
      subject.complete();
    });

    return subject;
  }

  /**
   * Fetch all data from a server
	 * @returns <Observable> Observable object
   */
  public fetchAll(): ReplaySubject<any> {

    let subject: ReplaySubject<any> = new ReplaySubject(1);

    this.isLoading = true;

    this.http.get(this.getApiUrl() + '/all').subscribe((response: ResponseInterface) => {
      this.isLoading = false;

      subject.next(response);
      subject.complete();
    }, err => {
      this.isLoading = false;

      subject.next(err.error);
      subject.complete();
    });

    return subject;
  }


  // ============== PAGINATION ==============

  public paginationConfig: PaginationDescriptorInterface;

  /**
   * Check if recieved data is paginated.
   * If they are then deseralize paginator config.
   * @param data <RestPaginationDescriptorInterface> Pagination config from REST
   */
  private deserializePagginator(data: RestPaginationDescriptorInterface<T>|any): void {
    if (!data.totalPages) return;
    this.paginationConfig = {
      totalElements: data.totalElements,
      totalPages: data.totalPages,
      pageNumber: data.number,
      pageSize: data.size,
      numberOfElements: data.numberOfElements,
      lastPage: data.last,
      firstPage: data.first,
      empty: data.empty
    };
  }

  // ============== FILTERS ===================

  /**
   * Serialize filters
   */
  private serializeFilter(): void {
    let stringArray = [];
    for(let filter of this.remoteFilter){
      stringArray.push(filter.attributeName + ':' + filter.operator + ':' + filter.value);
    }

    this.fetchOptions.filter = stringArray.join(';');
  }

  // ============== URL PARAMETERS ===================

  /**
   * Serialize url parameters
   */
  private serializeUrlParams(): void {
    let stringArray = [];
    for(let param of this.remoteParams){
      stringArray.push(param.key + '=' + param.value);
    }

    this.fetchOptions.params = stringArray.join('&');
  }


  // ============== HELPER METHODS ==============

  /**
   * Get server resource URL
   */
  private getApiUrl(options?: Object): string {
    return environment.serviceUrl + '/' + this.url + this.paramsToString(<any>options);
  }

	/**
	 * Converte params object to string
	 * @param <HttpGetRequestParamsInterface> params
   * @returns <string> Encoded URI
	 */
	protected paramsToString(params: HttpGetRequestParamsInterface): string {
		let p: string = '';
		if (params) {
			p = '?';
			let keys = Object.keys(params);
			for (let i in keys) {
				if (p !== '?') {
					p += '&';
        }
        if(params[keys[i]]){
          if(keys[i] === 'params'){
            let array = params[keys[i]].split('&');
            array.forEach((item, index) => {
              if(index) p += '&';
              let param = item.split('=');
              p += encodeURIComponent(param[0]) + '=' + encodeURIComponent(param[1]);
            });
          }else{
            p += encodeURIComponent(keys[i]) + '=' + encodeURIComponent(params[keys[i]]);
          }
        }
			}
		}
		return p;
	}

}

// ============== INTERFACES FOR DI ==============

/**
 * Key for service provider injection
 */
export const STORE_CONFIG_PROVIDER = 'StoreServiceModelsConfig';

/**
 * Descriptor for service provider injection
 */
export interface StoreConfigInterface {
  storeDescriptors: Array<{
    modelType: ModelType;
    storeName: string;
    url: string;
  }>;
}

// ============== INTERFACES PRIVATE ==============

interface StoreFetchOptionsInterface {
  page?: number;
  size?: number;
  sort?: string;
  search?: string;
  filter?: string;
  params?: string;
}

interface RestPaginationDescriptorInterface<T> {
  content: Array<T>;
  totalElements: number;
  totalPages: number;
  number: number;
  size: number;
  numberOfElements: number;
  last: boolean;
  first: boolean;
  empty: boolean;
}

interface PaginationDescriptorInterface {
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  numberOfElements: number;
  lastPage: boolean;
  firstPage: boolean;
  empty: boolean;
}

interface FilterItemInterface {
  attributeName:string;
  operator: string;
  value: string;
}

interface UrlParamInterface {
  key:string;
  value: string;
}
