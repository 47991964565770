import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from "ng-zorro-antd";

import { Store, ModelService, SimpleRestManagerService } from 'src/app/common';
import { FlightModel } from '../models/flight.model';
import { ProjectModel } from '../models/project.model';
import { CreateFlightModal } from '../modals/createFlight/createFlight.modal';
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, MODULE_PATH_DRONE_PROJECTS, MODULE_PATH_DRONE_FLIGHTS, MODULE_PATH_DRONE_VIDEOS } from '../drone.routes';
import { CreateCompositionModal } from "../modals/createComposition/createComposition.modal";

@Component({
  selector: "flightsPage",
  templateUrl: "./flightsPage.html",
  styleUrls: ["./flightsPage.scss"]
})
export class FlightsPageComponent implements OnInit {

  public clientId: number;

  public projectId: number;

  public project: ProjectModel;

  public storeProject: Store<ProjectModel>;

  public store: Store<FlightModel>;

  public tableData = [];

  constructor(private modelService: ModelService,
              private ts: TranslateService,
              public srms: SimpleRestManagerService,
              private modalService: NzModalService,
              private message: NzMessageService,
              private router: Router,
              private route: ActivatedRoute){
  }

  public ngOnInit(){
    this.route.params.subscribe(params => {
      this.clientId = typeof(params['clientId']) === 'undefined' ? null : +params['clientId'];
      this.projectId = typeof(params['projectId']) === 'undefined' ? null : +params['projectId'];
      this.init()
    });
  }

  public init(){
    this.storeProject = this.modelService.getStore('ProjectModel');
    this.storeProject.getDataById(this.projectId).subscribe(res => {
      this.project = res;
    });

    this.store = this.modelService.getStore('FlightModel');
    this.store.events.afterLoad.subscribe(() => {
      this.tableData = [...this.store.getData()];
    });

    this.store.removeSimpleFilter();
    this.store.cleanFilters();
    this.store.setSorter('name', 'ascend', false);

    if(this.projectId){
			let filter = {
        attributeName: 'project.id',
        operator: '=',
        value: this.projectId
      };
      this.store.addFilter(<any>filter);
		}

    this.store.fetch();
  }

  /**
	 * On new flight click
	 */
	public onNewFlightClick(): void {
    const modal: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createFlightModal.title.new'),
      nzContent: CreateFlightModal,
      nzComponentParams: {
        projectId: this.projectId
      }
    });
  }

  /**
	 * On edit flight click
	 */
	public onEditFlightClick(flight: FlightModel): void {
    const modalEdit: NzModalRef = this.modalService.create({
      nzTitle: this.ts.instant('drone.createFlightModal.title.edit'),
      nzContent: CreateFlightModal,
      nzComponentParams: {
        model: flight
      }
    });
  }

  public deleteFlight(flight: FlightModel): void {
    this.store.delete(flight).subscribe(res => {
      if(res){
        this.message.success(this.ts.instant("drone.videosPage.successMsg.delete"));
      }else{
        this.message.error(this.ts.instant("drone.videosPage.errorMsg.delete"));
      }
    });
  }

  public goToClients(): void {
    this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS]);
  }

  public goToProjects(): void {
    if(this.clientId){
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS]);
    }else{
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS]);
    }
  }

  public goToVideos(id: number): void {
    if(this.clientId){
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, this.clientId, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS, id, MODULE_PATH_DRONE_VIDEOS]);
    }else{
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS, this.projectId, MODULE_PATH_DRONE_FLIGHTS, id, MODULE_PATH_DRONE_VIDEOS]);
    }
  }

  public onCreateCompositionBtnClick(): void {
    this.modalService.create({
      nzTitle: this.ts.instant('drone.playWizardModal.activatonBtn'),
      nzContent: CreateCompositionModal,
      nzComponentParams: {
        clientId: this.clientId,
        projectId: this.projectId
      }
    });
  }

}
