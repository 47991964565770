import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

import { MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN } from 'src/app/authentication';
import { SimpleRestManagerService } from 'src/app/common';
import { MODULE_PATH_DRONE_CLIENTS, MODULE_PATH_DRONE_PROJECTS } from '../drone.routes';

@Component({
  selector: "mainContainer",
  templateUrl: "./mainContainer.html",
  styleUrls: ["./mainContainer.scss"]
})
export class MainContainerComponent {

  public routes = {
    clients: MODULE_PATH_DRONE_CLIENTS,
    projects: MODULE_PATH_DRONE_PROJECTS
  };

  public lang: string;

  public year: string = new Date().getFullYear().toString();

  constructor(public srms: SimpleRestManagerService,
              private router: Router,
              private translate: TranslateService){

    this.lang = localStorage.getItem("APP_LANG");
  }

  /**
	 * On logout click
	 */
	public logout(): void {
    this.srms.logout();
    this.srms.removeCredentials();
    this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN]);
  }

  public changeLang(): void {
    if (this.lang == 'en') {
      this.lang = 'hr';
    }else{
      this.lang = 'en';
    }
    localStorage.setItem("APP_LANG", this.lang);

    this.translate.use(this.lang);
  }

}
