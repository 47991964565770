import {
  AbstractModel,
  Id,
  Column,
  OneToOne,
  DateValue
} from "../../common";
import { ClientModel } from './client.model';

/**
 * Model for a Project REST object
 */
export class ProjectModel extends AbstractModel {

  @Id
  @Column()
  public id: number;

  @Column()
  public name: string;

  @Column()
  public description: string;

  @DateValue
  @Column()
  public startDate: Date;

  @DateValue
  @Column()
  public endDate: Date;

  @OneToOne(ClientModel)
  public client?: ClientModel;

}
