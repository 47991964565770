import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";

import { SimpleRestManagerService, UtilService } from "../../common";
import { MODULE_PATH_AUTH, MODULE_PATH_AUTH_REGISTER, MODULE_PATH_AUTH_LOGIN } from "../index";

@Component({
  selector: "forgot-password",
  templateUrl: "./forgotPassword.html",
  styleUrls: ["./forgotPassword.scss"]
})
export class ForgotPasswordComponent {

  public isLoading: boolean = false;

  public form: FormGroup;

  constructor(private srms: SimpleRestManagerService,
              public util: UtilService,
              private ts: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder) {

    this.createForm();
  }

  /**
	 * Create form
	 */
	private createForm(): void {
		this.form = this.fb.group({
			email: [null, [Validators.required, Validators.email]]
    });

  }

  // ========== DOM LISTENERS ==========

	/**
	 * On submit button click
	 */
	public onSubmitBtnClick(): void {


  }

  /**
	 * Go to register screen
	 */
	public goToRegister(): void {
    this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_REGISTER]);
  }

  /**
	 * Go to login screen
	 */
	public goToLogin(): void {
    this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN]);
  }

}
