import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MainComponent } from './main.component';
import { MainRouting } from './main.routing';

import { CommonModule } from '../common';
import { AuthenticationModule } from '../authentication/authentication.module';
import { DroneModule } from '../drone/drone.module';

@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    MainRouting,

    CommonModule,
    AuthenticationModule,
    DroneModule
  ],
  providers: [],
  bootstrap: [MainComponent]
})
export class MainModule { }
