import * as turf from '@turf/turf';
import {
  AbstractModel,
  Id,
  Column,
  OneToOne
} from "../../common";
import { FlightModel } from './flight.model';
import { SRTModel } from './srt.model';

/**
 * Model for a Video REST object
 */
export class VideoModel extends AbstractModel {

  @Id
  @Column()
  public id: number;

  @Column()
  public path: string;

  @Column()
  public srtFile: string;

  @Column()
  public srts?: Array<SRTModel>;

  @OneToOne(FlightModel)
  public flight?: FlightModel;

  public pointsCollection: turf.helpers.FeatureCollection<turf.helpers.Point>;

  public googleLineCoords: Array<{ lat: number, lng: number }>;

  public tableDataAll: any = [];

  public isDataPrepared: boolean = false;

  public prepareData(): void {
    if(this.isDataPrepared) return;

    if (!this.srts) this.srts = [];

    if (this.srts.length === 0) return;

    let tableDataAll = [];
    let points: Array<turf.helpers.Feature<turf.helpers.Point>> = [];
    let googleLineCoords: Array<{ lat: number, lng: number }> = [];

    this.srts.forEach(srt => {
      if(!srt.data || srt.data.length === 0) srt.data = [];
      let data = srt.data.map(item => { return { ...item, srtId: srt.id, srtTime: srt.time } });
      tableDataAll = [...tableDataAll, ...data];
      points.push(turf.point([srt.latitude, srt.longitude], { videoId: this.id, srtId: srt.id }));
      googleLineCoords.push({ lat: srt.latitude, lng: srt.longitude });
    });

    this.pointsCollection = turf.featureCollection(points);
    this.googleLineCoords = googleLineCoords;
    this.tableDataAll = tableDataAll;
  }

}
