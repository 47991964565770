import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MODULE_PATH_AUTH } from '../authentication';

const MainRoutes: Routes = [
    { path: '',   redirectTo: MODULE_PATH_AUTH,   pathMatch: 'full' },
    { path: '**', redirectTo: MODULE_PATH_AUTH,   pathMatch: 'full'}
];

export const MainRouting: ModuleWithProviders = RouterModule.forRoot(MainRoutes);
