import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';

import { Store, ModelService } from 'src/app/common';
import { ClientModel } from '../../models/client.model';

@Component({
  selector: "createClientModal",
  templateUrl: "./createClient.html"
})
export class CreateClientModal {

  public edit: boolean = false;

  public isLoading: boolean = false;

  public form: FormGroup;

  public store: Store<ClientModel>;

  public model: ClientModel;

  constructor(private modal: NzModalRef,
              private modelService: ModelService,
              private message: NzMessageService,
              private ts: TranslateService,
              private fb: FormBuilder,) {

    this.store = this.modelService.getStore('ClientModel');
    this.createForm();

    setTimeout(() => {
      if(this.model !== undefined){
        this.edit = true;
        this.fillForm();
      }else{
        this.model = new ClientModel();
      }
    }, 0);
  }

  private createForm(): void {
		this.form = this.fb.group({
			clientName: [null, Validators.required]
		});
  }

  private fillForm(): void {
		this.model.populateFormWihtModel(this.form);
  }

  private destroyModal(success: boolean): void {
    this.modal.destroy({data: success});
  }

  public onCancelClick(): void {
    this.destroyModal(false);
  }

  public onSubmitClick(): void {
    this.form.controls.clientName.setValue(this.form.value.clientName.trim());

    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (!this.form.valid) return;

    this.edit ? this.update() : this.create();
  }

  private create(): void {
    this.isLoading = true;

    this.model.populateModelWithForm(this.form);
    this.store.create(this.model).subscribe((res) => {
      this.isLoading = false;
      if(res){
        this.message.success(this.ts.instant('drone.createClientModal.create.succesMsg'));
        this.destroyModal(true);
      }else{
        this.message.error(this.ts.instant('drone.createClientModal.create.errorMsg'));
      }
    });
  }

  private update(): void {
    this.isLoading = true;

    this.model.populateModelWithForm(this.form);
    this.store.update(this.model).subscribe((res) => {
      this.isLoading = false;
      if(res){
        this.message.success(this.ts.instant('drone.createClientModal.update.succesMsg'));
        this.destroyModal(true);
      }else{
        this.message.error(this.ts.instant('drone.createClientModal.update.errorMsg'));
      }
    });
  }
}
