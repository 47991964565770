import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { SimpleRestManagerService } from '../rest/simpleRestManager.service';
import { MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN } from '../../authentication';

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private srms: SimpleRestManagerService) {}

  /**
   * Interceptor for every REST request
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let header = {
      //'Content-Type': 'application/json'
    };

    //JWT token
    let token = localStorage.getItem("AUTH_TOKEN");
    if (token) header['Authorization'] = 'Bearer ' + token;

    if(this.srms.isTokenOnExpiration()){
      //Refresh token
      let refresh = localStorage.getItem("REFRESH_TOKEN");
      if (refresh) header['X-Refresh-Token'] = refresh;
    }

    req = req.clone({
      setHeaders: header
    });

    return next.handle(req)
      .pipe(map((event: HttpEvent<any>) => {

        //Refresh token
        if(event instanceof HttpResponse && (<any>event).headers.has("new-token-flag")){
          localStorage.setItem('AUTH_TOKEN', (<any>event).headers.get("new-token"));
          localStorage.setItem('REFRESH_TOKEN', (<any>event).headers.get("new-refresh-token"));
        }

        if(event instanceof HttpErrorResponse && event.status === 401){
          this.srms.removeCredentials();
          this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN]);
        }

        return event;
      }));
  }

}
