import { FormGroup } from "@angular/forms";
import { ModelCollection } from "./modelCollection";
import * as moment from 'moment';

/**
 * Abstract class for models
 */
export class AbstractModel {

  /**
   * Contructor
   * @param data <data> [Optional] Data object for deserialization
   */
  constructor(data?: Object) {
    this.deserialize(data);
  }

  /**
   * Property that is holding name of primary key attribute
   */
  private _primaryKeyAttribute: string;

  /**
   * Property that is holding list of all attributes in Model
   */
  private _attributes: Array<AttributeDescriptor>;

  /**
   * Get primary key value
   * @returns <any> Primari key value
   */
  public getPk(): any {
    return this[this._primaryKeyAttribute];
  }

  /**
   * Set a primary key value
   * @param val Primary key value
   */
  public setPk(val: any): any {
    this[this._primaryKeyAttribute] = val;
  }

  /**
   * Populate form object with a values from a Model
   * @param form <FormGroup> FormGroup instance
   */
  public populateFormWihtModel(form: FormGroup): void {
    this._attributes.forEach(attr => {
      if (attr.type !== 'simple' && attr.type !== 'date') return;
      let c = form.controls[attr.modelName];
      if (c) {
        c.setValue(this[attr.modelName]);
      }
    });
  }

  /**
   * Populate Model with a form values
   * @param form <FormGroup> FormGroup instance
   */
  public populateModelWithForm(form: FormGroup): void {
    this._attributes.forEach(attr => {
      if (attr.type !== 'simple' && attr.type !== 'date' && attr.type !== 'object') return;
      let c = form.controls[attr.modelName];
      if (c) {
        this[attr.modelName] = c.value;
      }
    });
  }

  /**
   * Is new model (not persisted)
   * @returns <boolean> Return if model is new
   */
  public isNew(): boolean {
    let pk = this.getPk();
    return typeof(pk) === 'undefined' || pk < 0;
  }

  /**
   * Get an item from model
   */
  public getItem(key: string): any {
    return this[key];
  }

  /**
   * Set item into model
   */
  public setItem(key: string, value: any): void {
    this[key] = value;
  }

  /**
   * Translate REST object to Model
   * @param data <Object> Object for serialization
   */
  public deserialize(data: Object): void {
    this._attributes.forEach(attr => {
      switch (attr.type) {
        case 'simple':
          this[attr.modelName] = data ? data[attr.restName] : this[attr.modelName];
          break;
        case 'collection':
          this[attr.modelName] = new ModelCollection(attr.modelType, data ? data[attr.restName] : null);
          break;
        case 'object':
          this[attr.modelName] = (data && data[attr.restName]) ? new attr.modelType(data[attr.restName]) : null;
          break;
        case 'date':
          this[attr.modelName] = (data && data[attr.restName]) ? moment(data[attr.restName]).toDate() : null;
          break;
        default:
          throw "[ERROR - AbstractModel deserialize] Unsuported model attribute type!";
      }
    });
  }

  /**
   * Translate Model to REST object
   * @returns <Object> Serialized object
   */
  public serialize(): Object {
    let object = {};
    for (let attr of this._attributes) {
      switch (attr.type) {
        case 'simple':
          object[attr.restName] = this[attr.modelName];
          break;
        case 'collection':
          object[attr.restName] = this[attr.modelName] ? this[attr.modelName].serialize() : null;
          break;
        case 'object':
          object[attr.restName] = this[attr.modelName] ? this[attr.modelName].serialize() : null;
          break;
        case 'date':
          object[attr.restName] = this[attr.modelName] ? moment(this[attr.modelName]).format('YYYY-MM-DD HH:mm:ss') : null;
          break;
        default:
          throw "[ERROR - AbstractModel deserialize] Unsuported model attribute type!";
      }
    }
    return object;
  }
}

// ========= EXPORTED DECORATORS =========

/**
 * Set a primary key attribute
 */
export function Id(target: AbstractModel, key: string) {
  (<any>target)._primaryKeyAttribute = key;
}

/**
 * Add an attribute to persistence keylist
 */
export function Column(name?: string) {
  return function (target: AbstractModel, key: string) {
    _addAtributeAsModelProperty(target, key, name);
  };
}

/**
 * OneToMany data relation
 */
export function OneToMany(type: ModelType, name?: string) {
  return function (target: AbstractModel, key: string) {
    _addAtributeAsModelProperty(target, key, name, type, 'collection');
  };
}

/**
 * OneToMany data relation
 */
export function OneToOne(type: ModelType, name?: string) {
  return function (target: AbstractModel, key: string) {
    _addAtributeAsModelProperty(target, key, name, type, 'object');
  };
}

/**
 * Add an attribute to persistence keylist
 */
export function DefaultValue(value: any) {
  return function (target: AbstractModel, key: string) {
    (<any>target)[key] = value;
  };
}

/**
 * Set field as date
 */
export function DateValue(target: AbstractModel, key: string) {
  let obj = (<any>target)._attributes.find(x => x.modelName === key);
  obj.type = 'date';
}

// ========= PRIVATE HELPER FUNCTIONS =========

/**
 * Add attribute to list of model attributes
 * @param target <AbstractModel> Prototype instance
 * @param key <string> Model attribute name
 * @param mappedKey <string> [Optional] REST attribute name
 */
function _addAtributeAsModelProperty(target: AbstractModel, key: string, mappedKey: string = null, modelType: ModelType = null, type: AttributeDescriptorType = 'simple'): void {
  let model = <any>target;

  if (!model._attributes) model._attributes = [];

  model._attributes.push({
    type: type,
    modelType: modelType,
    modelName: key,
    restName: mappedKey ? mappedKey : key
  });

  if (type === 'collection') model[key] = new ModelCollection(modelType);
  else if (type === 'object') model[key] = new modelType();
}

// ========= PROTECTED INTERFACES =========

/**
 * Model constructor interface
 */
export interface ModelType {
  new(data?: Object): AbstractModel;
}

// ========= PRIVATE INTERFACES =========

interface AttributeDescriptor {
  type: AttributeDescriptorType;
  modelType?: ModelType;
  modelName: string;
  restName: string;
}
type AttributeDescriptorType = 'simple' | 'collection' | 'object' | 'date';
