import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from '../../../environments/environment';

import { SimpleRestManagerService, UtilService } from "../../common";
import { MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS, MODULE_PATH_DRONE_PROJECTS } from "../../drone";
import { MODULE_PATH_AUTH, MODULE_PATH_AUTH_REGISTER, MODULE_PATH_AUTH_FORGOT_PASSWORD } from "../index";


@Component({
  selector: "login",
  templateUrl: "./login.html",
  styleUrls: ["./login.scss"]
})
export class LoginComponent {

  public isLoading: boolean = false;

  public form: FormGroup;

  constructor(private srms: SimpleRestManagerService,
              public util: UtilService,
              private ts: TranslateService,
              private router: Router,
              private fb: FormBuilder,
              private message: NzMessageService) {

    this.checkIfIsLoggedIn();
    this.createForm();
  }

  /**
   * If user is already logged in just redirect him
   */
  private checkIfIsLoggedIn(): any {
    if(this.srms.isLoggedIn()) {
      this.srms.isAdmin() ?
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS]) :
      this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS]);
    }
  }

  /**
	 * Create login form
	 */
	private createForm(): void {
		this.form = this.fb.group({
			email: [environment.production ? '' : 'pero@pero.com', [Validators.required, Validators.email]],
			password: [environment.production ? '' : '1234567', Validators.required]
		});
  }

  // ========== DOM LISTENERS ==========

	/**
	 * On sign in button click
	 */
	public onSignInBtnClick(): void {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (!this.form.valid) return;

    this.isLoading = true;
    this.srms.login(this.form.value.email, this.form.value.password).subscribe(res => {
      this.message.success(this.ts.instant("authentication.login.succesMsg"));

      if(res.data[0].user.isAdmin){
        this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_CLIENTS]);
      }else{
        this.router.navigate([MODULE_PATH_DRONE, MODULE_PATH_DRONE_PROJECTS]);
      }
    }, err => {
      this.isLoading = false;
      let code = err.error.error[0].errorCode;
      switch(code){
        case 404: {
          this.message.error(this.ts.instant("authentication.login.errorCodes." + code));
          break;
        }
        default: this.message.error(this.ts.instant("common.msgs.error"));
      }
    });

  }

  /**
	 * Go to register screen
	 */
	public goToRegister(): void {
    this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_REGISTER]);
  }

  /**
	 * Go to forgot password screen
	 */
	public goToForgotPassword(): void {
    this.router.navigate([MODULE_PATH_AUTH, MODULE_PATH_AUTH_FORGOT_PASSWORD]);
  }

}
