import { Injectable, Injector } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Injectable()
export class UtilService {	

	/**
	 * Determine if a field has an error with the passed key.
	 * @param form <FormGroup> Form
	 * @param name  <string> Control name
	 * @param errorKey <string> Validator key
	 * @param isSubmited  <boolean> [Optional] Is form submited
	 * @returns {boolean} True if field has error, otherwise false
	 */
	public fieldHasError(form: FormGroup, name: string, errorKey: string | string[], isSubmited?: boolean): boolean {
		let control = form.controls[name];
		let errorList = errorKey;

		if (!Array.isArray(errorKey)) {
			errorList = [errorKey];
		}
		
		let status = control && control.invalid && !!control.errors;
		if (status) {
			status = false;
			for (let item of errorList) {
				if (control.errors[item]) {
					if(!(item === 'email' && (control.value === null || control.value === ''))){
						status = true;
						break;
					}					
				}
			}
		}

		if (typeof (isSubmited) === 'undefined' || isSubmited === false) {
			status = status && control.touched && control.dirty;
		}

		return status;
	}
	
}
