import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AbstractService } from '../abstract/service.abstract.service';
import { CommentModel } from 'src/app/drone/models/comment.model';

@Injectable()
export class SimpleRestManagerService extends AbstractService {

  // ======================= AUTH ========================

  /**
	 * Check user credentials on server
	 * @param <string> email Email
	 * @param <string> password Password
	 * @returns <Observable> Observable object for user authentification
	 */
	public login(email: string, password: string): Observable<any> {
    let url = [this.serviceUrl, 'login'].join('/');

    let data = {
      email,
      password
		};

		return <Observable<any>>this.http.post(url, data).pipe(tap((response: any) => {
      this.saveCredentials(response.data);
		}));
  }

  /**
	 * Logout user
	 *
	 * @returns <Observable> Observable object
	 */
	public logout(): Observable<any> {
    let url = [this.serviceUrl, 'logout'].join('/');

		return <Observable<any>>this.http.post(url, null);
	}

	/**
	 * Register new user
	 * @param <string> email Email
	 * @param <string> firstName First name
	 * @param <string> lastName Last name
	 * @param <string> password Password
	 * @returns <Observable> Observable object for user authentification
	 */
	// public register(email: string, password: string, firstName: string, lastName: string): Observable<any> {
  //       let url = [this.serviceUrl, 'users', 'register'].join('/');

  //       let data = {
  //           email: email,
	// 		password: password,
	// 		profile: {
	// 			full_name: firstName + ' ' + lastName,
	// 			language: 'en'
	// 		}
	// 	};

	// 	return <Observable<any>>this.http.post(url, data);
	// }

	/**
	 * Reset password
	 * @param <string> email Email
	 * @returns <Observable> Observable object
	 */
	// public resetPassword(email: string): Observable<any> {
	// 	let url = [this.serviceUrl, 'users', 'claim'].join('/');

	// 	let data = {
	// 		email
	// 	};

	// 	return <Observable<any>>this.http.post(url, data);
	// }


	/**
	 * Save user credentials to local storage
	 * @param data
	 */
	public saveCredentials(data: any): void {
		if(!data[0].token) return;
    localStorage.setItem('AUTH_TOKEN', data[0].token);
    localStorage.setItem('REFRESH_TOKEN', data[0].refreshToken);
    localStorage.setItem('IS_ADMIN', data[0].user.isAdmin);
    localStorage.setItem('CURRENT_USER', JSON.stringify(data[0].user));
	}

	/**
	 * Remove user credentials from local storage
	 */
	public removeCredentials(): void {
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    localStorage.removeItem('IS_ADMIN');
    localStorage.removeItem('CURRENT_USER');
  }

  /**
	 * Check if user is loggedin
	 * @returns <boolean> Is user logged in
	 */
	public isLoggedIn(): boolean {
    return !!this.getUserToken() && this.isTokenValid();
	}

  /**
	 * Get user GWT token from local storage
	 * @returns <string> GWT token
	 */
	public getUserToken(): string {
		return localStorage.getItem("AUTH_TOKEN");
	}

	/**
	 * Check JWT token validity
	 * @returns <boolean> Returns true if token is valid and not expired
	 */
	public isTokenValid(): boolean {
		let token = localStorage.getItem('AUTH_TOKEN');
		let isExpired;
		try {
			isExpired = this.jwtUtil.isTokenExpired(token);
		} catch (error) {
			return false;
		}
		return !isExpired;
  }

  /**
	 * Check JWT token expiration
	 * @returns <boolean> Returns true if token will expire in next 10min
	 */
	public isTokenOnExpiration(): boolean {
		let token = localStorage.getItem('AUTH_TOKEN');
		let isExpired;
		try {
      let expDate: any = this.jwtUtil.getTokenExpirationDate(token);
      let today: any = new Date();
      let diffMs = Math.abs(expDate - today); // milliseconds
      let diffMin = diffMs / 1000 / 60;   // minutes

      isExpired = diffMin < 10 ? true : false;
		} catch (error) {
			return false;
		}
		return isExpired;
  }

  /**
	 * Is current user admin
	 * @returns <boolean> Is current user admin
	 */
	public isAdmin(): boolean {
    let admin = localStorage.getItem("IS_ADMIN");
		return admin && admin == "true";
  }

  /**
	 * Get current user
	 * @returns <object> Current user
	 */
	public getCurrentUser(): any {
    let user = localStorage.getItem("CURRENT_USER");
		return JSON.parse(user);
	}

	/**
	 * Get comments
	 * @returns Array of comments with asssigned parameters
	 */
	public getComments(): Observable<any> {
		let url = [this.serviceUrl, 'data/comments'].join('/');
		return <Observable<{data: Array<CommentModel>}>>this.http.get(url);
	}
}
