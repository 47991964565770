import { NgModule } from '@angular/core';
import { CommonModule as AnCommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { SimpleRestManagerService } from './rest/simpleRestManager.service';
import { CustomHttpInterceptorService } from './http/customHttpInterceptor.service';
import { AuthGuardService } from './http/authGuard.service';
import { UtilService } from './util/util.service';
import { ModelService } from './http/model.service';

registerLocaleData(en);

@NgModule({
  imports: [
    AnCommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgZorroAntdModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

  ],

  exports: [
    AnCommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    NgZorroAntdModule
  ],

  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptorService,
      multi: true
    },
    SimpleRestManagerService,
    AuthGuardService,
    UtilService,
    ModelService
  ]
})
export class CommonModule {

  constructor(translate: TranslateService) {
    this.initI18n(translate);
  }

  /**
   * Initialize default application language
   * @param <TranslateService> translate
   */
  private initI18n(translate: TranslateService): void {
    let lang = localStorage.getItem("APP_LANG");
    if(!lang) lang = "en";

    translate.setDefaultLang('en');
    translate.use(lang);
    localStorage.setItem("APP_LANG", lang)
  }
}

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
