import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MODULE_PATH_AUTH, MODULE_PATH_AUTH_LOGIN, MODULE_PATH_AUTH_REGISTER, MODULE_PATH_AUTH_FORGOT_PASSWORD } from './authentication.routes';
import { MainContainerComponent } from './mainContainer/mainContainer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';


const AuthenticatioRoutes: Routes = [
	{
		path: MODULE_PATH_AUTH, component: MainContainerComponent,
		children: [
			{ path: '', redirectTo: MODULE_PATH_AUTH_LOGIN, pathMatch: 'full' },

			{ path: MODULE_PATH_AUTH_LOGIN,							component: LoginComponent },
			// { path: MODULE_PATH_AUTH_REGISTER,						component: RegisterComponent },
			// { path: MODULE_PATH_AUTH_FORGOT_PASSWORD,				component: ForgotPasswordComponent },

			{ path: '',   redirectTo: MODULE_PATH_AUTH_LOGIN,		pathMatch: 'full' },
			{ path: '**', redirectTo: MODULE_PATH_AUTH_LOGIN,		pathMatch: 'full'}
		]
	}
];

export const AuthenticatioRouting: ModuleWithProviders = RouterModule.forRoot(AuthenticatioRoutes);
