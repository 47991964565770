import { StoreConfigInterface } from "../common";
import { ClientModel } from "./models/client.model";
import { UserModel } from './models/user.model';
import { ProjectModel } from './models/project.model';
import { FlightModel } from './models/flight.model';
import { VideoModel } from './models/video.model';
import { CSVModel } from './models/csv.model';
import { CommentModel } from './models/comment.model'

export const storeServiceConfig: StoreConfigInterface = {
  storeDescriptors: [
    { modelType: ClientModel,        storeName: 'ClientModel',        url: 'client' },
    { modelType: UserModel,          storeName: 'UserModel',          url: 'user' },
    { modelType: ProjectModel,       storeName: 'ProjectModel',       url: 'project' },
    { modelType: FlightModel,        storeName: 'FlightModel',        url: 'flight' },
    { modelType: VideoModel,         storeName: 'VideoModel',         url: 'video' },
    { modelType: CSVModel,           storeName: 'CSVModel',           url: 'csv' },
    { modelType: CommentModel,       storeName: 'CommentModel',       url: 'data/comments' }
  ]
}
