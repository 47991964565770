import { NgModule } from '@angular/core';

import { CommonModule } from '../common';
import { AuthenticatioRouting } from './authentication.routing';
import { MainContainerComponent } from './mainContainer/mainContainer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';

@NgModule({
  imports: [
    CommonModule,
    AuthenticatioRouting
  ],
  
  declarations: [
    MainContainerComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent
  ]
})
export class AuthenticationModule { }
