import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../common/http/authGuard.service';
import {
    MODULE_PATH_DRONE,
    MODULE_PATH_DRONE_CLIENTS,
    MODULE_PATH_DRONE_USERS,
    MODULE_PATH_DRONE_PROJECTS,
    MODULE_PATH_DRONE_FLIGHTS,
    MODULE_PATH_DRONE_VIDEOS,
    MODULE_PATH_DRONE_PLAY
} from './drone.routes';

import { MainContainerComponent } from './mainContainer/mainContainer.component';
import { ClientsPageComponent } from './clientsPage/clientsPage.component';
import { UsersPageComponent } from './usersPage/usersPage.component';
import { ProjectsPageComponent } from './projectsPage/projectsPage.component';
import { FlightsPageComponent } from './flightsPage/flightsPage.component';
import { VideosPageComponent } from './videosPage/videosPage.component';
import { PlayPageComponent } from './playPage/playPage.component';

const DroneRoutes: Routes = [{
    path: MODULE_PATH_DRONE,
    component: MainContainerComponent,
    canActivateChild: [ AuthGuardService ],
    children: [
        { path: '',   redirectTo: MODULE_PATH_DRONE_PROJECTS, pathMatch: 'full' },

        //USERS
        { component: ProjectsPageComponent,     path: MODULE_PATH_DRONE_PROJECTS },
        { component: FlightsPageComponent,      path: MODULE_PATH_DRONE_PROJECTS + "/:projectId/" + MODULE_PATH_DRONE_FLIGHTS },
        { component: VideosPageComponent,       path: MODULE_PATH_DRONE_PROJECTS + "/:projectId/" + MODULE_PATH_DRONE_FLIGHTS + "/:flightId/" + MODULE_PATH_DRONE_VIDEOS },
        { component: PlayPageComponent,         path: MODULE_PATH_DRONE_PROJECTS + "/:projectId/" + MODULE_PATH_DRONE_FLIGHTS + "/:flightId/" + MODULE_PATH_DRONE_VIDEOS + "/:videoId/" + MODULE_PATH_DRONE_PLAY },
        { component: PlayPageComponent,         path: MODULE_PATH_DRONE_PLAY },

        //ADMINS
        { component: ClientsPageComponent,      path: MODULE_PATH_DRONE_CLIENTS },
        { component: UsersPageComponent,        path: MODULE_PATH_DRONE_CLIENTS + "/:clientId/" + MODULE_PATH_DRONE_USERS },
        { component: ProjectsPageComponent,     path: MODULE_PATH_DRONE_CLIENTS + "/:clientId/" + MODULE_PATH_DRONE_PROJECTS },
        { component: FlightsPageComponent,      path: MODULE_PATH_DRONE_CLIENTS + "/:clientId/" + MODULE_PATH_DRONE_PROJECTS + "/:projectId/" + MODULE_PATH_DRONE_FLIGHTS },
        { component: VideosPageComponent,       path: MODULE_PATH_DRONE_CLIENTS + "/:clientId/" + MODULE_PATH_DRONE_PROJECTS + "/:projectId/" + MODULE_PATH_DRONE_FLIGHTS + "/:flightId/" + MODULE_PATH_DRONE_VIDEOS },
        { component: PlayPageComponent,         path: MODULE_PATH_DRONE_CLIENTS + "/:clientId/" + MODULE_PATH_DRONE_PROJECTS + "/:projectId/" + MODULE_PATH_DRONE_FLIGHTS + "/:flightId/" + MODULE_PATH_DRONE_VIDEOS + "/:videoId/" + MODULE_PATH_DRONE_PLAY },


        { path: '',   redirectTo: MODULE_PATH_DRONE_PROJECTS,   			pathMatch: 'full' },
        { path: '**', redirectTo: MODULE_PATH_DRONE_PROJECTS,         pathMatch: 'full'}
    ]
  }
];

export const DroneRouting: ModuleWithProviders = RouterModule.forRoot(DroneRoutes);
