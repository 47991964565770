import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';

/**
 * Abstract class for service implementations 
 */
@Injectable()
export abstract class AbstractService {

	/**
	 * Main service URL
	 */
	protected serviceUrl: string = environment.serviceUrl;

	/**
	 * JWT Util class
	 */
	protected jwtUtil: JwtHelperService = new JwtHelperService();

	constructor(protected http: HttpClient, protected ts: TranslateService) {

	}

	/**
	 * Converte params object to string
	 * @param <HttpGetRequestParamsInterface> params 
	 */
	protected paramsToString(params: HttpGetRequestParamsInterface): string {
		let p: string = '';
		if (params) {
			p = '?';
			let keys = Object.keys(params);
			for (let i in keys) {
				if (p !== '?') {
					p += '&';
				}
				p += encodeURIComponent(keys[i]) + '=' + encodeURIComponent(params[keys[i]]);
			}
		}

		return p;
	}

	/**
	 * Convert user image
	 * @param imageURL 
	 */
	public convertImageUrl(imageURL: string): string {
		if(!imageURL) {
			return './assets/images/avatar-placeholder.png';
		}
		
		return imageURL;
	}

}

export interface HttpGetRequestParamsInterface {
	[index: string]: string;
}
